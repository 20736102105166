import { all } from 'redux-saga/effects';
import { getTelegramSettings } from './getTelegramSettings';
import { updateTelegramSettings } from './updateSettings';

export default function* recordingPrioritySaga() {
  yield all([
    getTelegramSettings(),
    updateTelegramSettings(),
  ]);
}
