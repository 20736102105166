import createRequestRest from '@src/api/createRequestRest';
import { kanbanSlice } from '../../slices/kanban/kanbanSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload.payload?.success(response);
  yield;
}

function* prepareFailure(res: any) {
  yield;
}

export const searchBranch = () => {
  return createRequestRest({
    url: '/api/v1/todolist/search/branch',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.searchBranchRequest,
    method: 'get',
  });
};
