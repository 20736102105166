import React, { useEffect } from 'react';
import { Checkbox } from '@src/components/checkbox';
import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { serviceSlice, servicesServiceSelector } from '@redux/slices/service/serviceSlice';

export const ServicesGroup: React.FC<any> = (props) => {
  const [options, setOptions] = React.useState<any>([]);
  const { group } = props;
  const services = useAppSelector(servicesServiceSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const options: any = [];

    group.services.forEach((service) => {
      options.push({ value: service.id, label: service.title, checked: service.is_active });
    });

    setOptions([...options]);
  }, [services]);

  const onChange = (checked, value) => {
    dispatch(
      serviceSlice.actions.setActiveRequest({ data: { id: value, active: checked } }),
    );

    dispatch(serviceSlice.actions.updateService({
      id: value,
      update: {
        is_active: checked,
      },
    }));
  };

  return (
    <div>
      <h3>{group.title}</h3>

      {options.map((option) => <Checkbox
        key={option.value}
        label={option.label}
        checked={option.checked}
        onChange={(e) => {
          onChange(e.target.checked, option.value);
        }}
      />)}
    </div>
  );
};
