import React from 'react';
import styled from 'styled-components';
import { Navbar } from './features/navbar';
import { Routing } from './core/routes';
import { useAppSelector } from './core/hooks';
import { authSlice, userAuthSelector } from '@redux/slices/auth/authSlice';
import Cookies from 'js-cookie';
import { useAppDispatch } from 'core/hooks';

enum LoadingStatus {
  not_loaded = 'not_loaded',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export const App: React.FC = () => {
  const user = useAppSelector(userAuthSelector);
  const dispatch = useAppDispatch();


  //[START] async get initial state from backend
  const [loadInitialStateStatus, setLoadInitialStateStatus] = React.useState<LoadingStatus>(LoadingStatus.not_loaded);

  if (loadInitialStateStatus === 'not_loaded') {
    const authToken = Cookies.get('TOKEN');

    if (authToken) {
      setLoadInitialStateStatus(LoadingStatus.loading);

      fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/auth/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            setLoadInitialStateStatus(LoadingStatus.error);
          }

          return response.json(); // Parse the JSON from the response
        })
        .then(data => {
          setLoadInitialStateStatus(LoadingStatus.loaded);

          const user = data.data.user;

          dispatch(authSlice.actions.setUser({ user }));
        })
        .catch(() => {
          Cookies.set('TOKEN', '');

          setLoadInitialStateStatus(LoadingStatus.loaded);
        });
    } else {
      setLoadInitialStateStatus(LoadingStatus.loaded);
    }
  }

  if (loadInitialStateStatus !== LoadingStatus.loaded) {
    return <>Loading ...</>;
  }
  //[END] async get initial state from backend


  return (
    <WrapperApp>
      {user && <Navbar />}
      <Content>
        <Routing />
      </Content>
    </WrapperApp>
  );
};

const WrapperApp = styled.div`
  min-height: 100vh;
  height: max-content;
  display: flex;
`;

const Content = styled.div`
  width: 100%;
  height: min-content;
  overflow-x: auto;
`;

