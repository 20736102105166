import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ButtonPros } from 'core/types';
import { IconCloud, IconPrinter } from 'icons';
import { Table } from './table';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { todolistSelector, todolistSlice } from '@redux/slices/todolist/todolistSlice';
import { kanbanSelector, kanbanSlice } from '@redux/slices/kanban/kanbanSlice';
import { initFilters, mapEntries } from './helpers';
import { MultiFilterDataProps } from 'components/filters/multiFilter';
import { InputSearch } from 'components/inputSearch';
import { PaginationBack } from 'features/paginationBack';
import { ItemFilter, NumberFiler } from 'features/numberFiler';
import { dataFilter, getRowsData, header } from './table/helpers';
import { ItemTable } from './table/types';
import { getFilterRequest } from 'components/filters/utils';
import debounce from 'lodash/debounce';
import config from 'api/config';
import { v4 as idV4 } from 'uuid';
import { PreloaderLarge } from 'components/preloader';
import { ModalChangeClient } from 'features/modals/modalChangeClient';
import { clientsSlice } from '@redux/slices/clients/clientsSlice';
import { EntryModal } from '@src/components/entry-modal/EntryModal';

export const TodolistContent: React.FC = () => {
  const deleteId = useRef<number>();
  const dispatch = useAppDispatch();
  const entries = useAppSelector(todolistSelector.entries);
  const count = useAppSelector(todolistSelector.count);
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [showModal, setShowModal] = useState(false);
  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<MultiFilterDataProps>(initFilters);
  const [size, setSize] = useState(dataFilter[0].value);
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<'desc' | 'asc' | null | undefined>(null);
  const [sort, setSort] = useState<any>('id');
  const [dataRows, setDataRows] = useState<ItemTable[][]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalClient, setShowModalClient] = useState(false);

  const handleRequest = useCallback(
    (search?: string) => {
      setLoading(true);
      dispatch(
        todolistSlice.actions.getAllTodolistRequest({
          payload: {
            data: {
              page,
              size,
              sort,
              sortType,
              ...getFilterRequest(filters),
            },
            success: () => {
              setCheckSearch(!!search);
              setLoading(false);
            },
          },
        }),
      );
    },
    [page, size, sort, sortType],
  );

  const buttons: ButtonPros[] = [
    {
      id: 1,
      type: 'icon',
      icon: IconCloud,
      onClick: () => console.log('print'),
    },
    {
      id: 2,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },
    {
      id: 3,
      title: 'Добавить заявку',
      onClick: () => setShowModal(true),
    },
  ];

  const item = useMemo(() => {
    const data = activeCard?.status_name;
    return {
      id: data?.id,
      name: data?.name,
      sort: data?.sort,
      prefix: idV4(),
    };
  }, [activeCard]);

  useEffect(() => {
    //запрос при сортировке/пагинации
    handleRequest();
  }, [handleRequest]);

  const handleChangeRow = (id?: number, remove?: boolean, flag_client?: boolean) => {
    if (remove) {
      deleteId.current = id;
    } else if (flag_client) {
      requestDataClient(id);
    } else {
      getActiveCardAndShowModal(id);
    }
  };

  const getActiveCardAndShowModal = (id: string | number | undefined) => {
    dispatch(
      kanbanSlice.actions.getCardTodoListRequest({
        payload: {
          data: { id },
          success: () => {
            setShowModal(true);
          },
        },
      }),
    );
  };

  const requestDataClient = (id: string | number | undefined) => {
    if (id) {
      dispatch(
        clientsSlice.actions.getClientRequest({
          payload: {
            data: {
              id,
            },
            success: () => setShowModalClient(true),
          },
        }),
      );
    }
  };

  const updateRequestData = () => {
    dispatch(kanbanSlice.actions.getAllTodoListRequest());
  };

  useEffect(() => {
    if (entries) {
      const handleClick = (id: number, flag_client?: boolean) => {
        handleChangeRow(id, false, flag_client);
      };
      const rows: ItemTable[][] = mapEntries(entries).map((item) => getRowsData(header, item, handleClick));
      setDataRows(rows);
    }
    return;
  }, [entries]);

  const handleClickPagination = (value: number) => {
    setPage(value);
  };

  const handleClickFilter = (d: ItemFilter) => {
    setSize(+d.value);
    setPage(1);
  };

  const showValue = (search: string) => {
    setCheckSearch(true);
    setPage(1);
    handleRequest(search);
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  return (
    <React.Fragment>
      <Content>
        <ContentHeader>
          <Header title="" buttons={buttons} />
          {/* ----  FILTERS ---- */}
          <FiltersBLock>
            {/* <WrapperFilter>
              <MultiFilter onClose={handleCloseFilters} data={filters} onChange={handleClickOnChangeFilter} />
            </WrapperFilter> */}
            {/* <WrapperFilter>
              <Sorting data={dataSorting} onChange={handleClickOnChangeSorting} />
            </WrapperFilter> */}
            <WrapperFilter>
              <InputSearch title="Поиск" placeholder="Поиск" onChange={handledOnChangeSearch} value={search} />
            </WrapperFilter>
          </FiltersBLock>
          {loading ? <div style={{ marginLeft: 24, marginTop: 24 }}><PreloaderLarge loading={loading} /></div> :
            dataRows && !!dataRows?.length ? (
              <>
                {/* ----  TABLE ---- */}
                <TableShell>
                  <TableShellIn>
                    <Table data={dataRows} setSortType={setSortType} sortType={sortType} setSort={setSort} sort={sort}
                           handleChangeRow={handleChangeRow} />
                  </TableShellIn>
                </TableShell>
                {/* ----  PAGINATION ---- */}
                <BlockPagination>
                  <WrapperPagination>
                    <PaginationBack page={page} count={count} size={size} onChange={handleClickPagination} />
                  </WrapperPagination>
                  <WrapperFilter>
                    <NumberFiler title="По" data={dataFilter} onClick={handleClickFilter} />
                  </WrapperFilter>
                </BlockPagination>
              </>
            ) : checkSearch ? (
              <TextNotData>Данные не найдены</TextNotData>
            ) : (
              <TextNotData>Данные отсутствуют</TextNotData>
            )}
        </ContentHeader>
      </Content>

      {showModal && (
        <EntryModal
          onCloseModal={() => setShowModal(false)}
          dataColumn={item}
        />
      )}

      <ModalChangeClient
        setShowModal={setShowModalClient}
        showModal={showModalClient}
        updateRequestData={updateRequestData}
      />
    </React.Fragment>
  );
};

export const Todolist: React.FC = (props) => {
  return (
    <TodolistContent {...props} />
  );
};

const TableShell = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const TableShellIn = styled.div`
  min-width: 1420px;
`;

const TextNotData = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px;
  font-size: 14px;
  text-align: center;
`;

const WrapperPagination = styled.div`
  padding: 0 8px 0 25px;
  border-right: 1px solid var(--color-grey);
`;

const BlockPagination = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
`;

const WrapperFilter = styled.div`
  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const FiltersBLock = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ContentHeader = styled.div`
  padding: 30px 30px 10px 30px;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 80px;
`;
