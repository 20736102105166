import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LayoutAuth } from './layoutAuth';
import { Title } from './style';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { DefaultButton } from 'components/buttons';
import { InputMack } from 'components/inputMask';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { authSlice, spaseAuthSelector } from '@redux/slices/auth/authSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Space } from '../../components/style';
import { message } from 'antd';
import debounce from 'lodash/debounce';
import config from 'api/config';

const initInputs = {
  name: '',
  surname: '',
  email: '',
  password: '',
  phone: '',
  remember: false,
};

export const SignUp: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const space = useAppSelector(spaseAuthSelector);

  // const token = useAppSelector(tokenCodeAuthSelector);
  const [validBtn, setValidBtn] = useState(false);
  const [code, setCode] = useState('');
  const [showCode, setShowCode] = useState(false);

  const [inputs, setInputs] = useState(initInputs);

  useEffect(() => {
    const checkedOff = Object.values(inputs).includes(false);
    setValidBtn(!Object.values(inputs).includes('') && !checkedOff);
  }, [inputs]);

  // useEffect(() => {
  //   if (valid.email(inputs.email)) {
  //     dispatch(authSlice.actions.getTokenRequest({ email: inputs.email }));
  //   }
  // }, [inputs.email]);

  const successRegistry = () => {
    dispatch(authSlice.actions.getSpaceAllRequest());
    // navigate('/');
    // localStorage.setItem('auth', 'true');
  };
  const handleClickSpace = (id: number) => () => {
    dispatch(
      authSlice.actions.postSpaceRequest({
        payload: {
          data: { id },
          success: () => {
            localStorage.setItem('auth', 'true');
            navigate('/');
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (space === null) return;
    if (space?.length === 1) {
      dispatch(
        authSlice.actions.postSpaceRequest({
          payload: {
            data: { id: space?.[0]?.id },
            success: () => {
              navigate('/');
              localStorage.setItem('auth', 'true');
            },
          },
        }),
      );
    } else {
      message.config({
        duration: 4,
      });
      void message.error('Пространства отсутствуют, обратитесь в поддержку');
    }
  }, [space]);

  //TODO: временно закомитил потом удалит если не требуется

  // useEffect(() => {
  //   if (token && inputs.email) {
  //     setTimeout(() => {
  //       dispatch(
  //         authSlice.actions.getConfirmRequest({
  //           payload: {
  //             data: { email: inputs.email, code: token },
  //             success: () => {
  //               successRegistry();
  //               dispatch(authSlice.actions.resetTokenCode());
  //             },
  //           },
  //         }),
  //       );
  //     }, 100);
  //   }
  // }, [token, inputs.email]);

  useEffect(() => {
    const code = params.get('code');
    const email = params.get('email') || localStorage.getItem('email_reg');
    if (localStorage.getItem('auth')) {
      return navigate('/');
    }
    if (code && email) {
      setShowCode(true);
      setCode(code);

      dispatch(
        authSlice.actions.getConfirmRequest({
          payload: {
            data: { email, code },
            success: () => {
              successRegistry();
              dispatch(authSlice.actions.resetTokenCode());
              localStorage.removeItem('email_reg');
            },
          },
        }),
      );
    }
  }, [params]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleClickRegister = () => {
    if (!validBtn) return;
    const data: Record<string, any> = { ...inputs };
    delete data.remember;
    dispatch(
      authSlice.actions.getRegisterRequest({
        payload: {
          data,
          success: () => {
            localStorage.setItem('email_reg', inputs.email);
            setShowCode(true);
          },
        },
      }),
    );
  };

  const showValue = (code: string) => {
    dispatch(
      authSlice.actions.getConfirmRequest({
        payload: {
          data: { email: inputs.email, code },
          success: () => {
            successRegistry();
            // dispatch(authSlice.actions.resetTokenCode());
          },
        },
      }),
    );
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), [inputs.email]);

  const handledOnChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    handledDebounce(e.target.value);
  };

  return (
    <LayoutAuth>
      <Content>
        <>
          {space?.length ? (
            <>
              <Body>
                <Title>Выберите пространство</Title>
                <SpaceBody>
                  {space.map((item: Record<string, any>) => (
                    <Space key={item.id} onClick={handleClickSpace(item.id)}>
                      {item.name}
                    </Space>
                  ))}
                </SpaceBody>
              </Body>
            </>
          ) : (
            <>
              {showCode ? (
                <>
                  <Body>
                    <Title>Регистрация</Title>
                    <TextEmail>{`Код подтверждения отправлен на адрес ${inputs.email}`}</TextEmail>
                    <WrapperInput>
                      <Input isClear placeholder={'Код подтверждения'} value={code} onChange={handledOnChangeCode} />
                    </WrapperInput>
                    <RemindButton>
                      <DefaultButton onClick={handleClickRegister}>Отправить код повторно</DefaultButton>
                    </RemindButton>
                  </Body>
                </>
              ) : (
                <>
                  <Body>
                    <Title>Регистрация</Title>
                    <Block>
                      <WrapperInput>
                        <Input
                          name={'name'}
                          isClear
                          placeholder={'Имя'}
                          value={inputs.name}
                          onChange={handleOnChange}
                          onClear={() => setInputs((prevState) => ({ ...prevState, name: '' }))}
                        />
                      </WrapperInput>
                      <WrapperInput>
                        <Input
                          name={'surname'}
                          isClear
                          placeholder={'Фамилия'}
                          value={inputs.surname}
                          onChange={handleOnChange}
                          onClear={() => setInputs((prevState) => ({ ...prevState, surname: '' }))}
                        />
                      </WrapperInput>
                      <WrapperInput>
                        <Input
                          name={'email'}
                          isClear
                          placeholder={'E-mail'}
                          value={inputs.email}
                          onChange={handleOnChange}
                          onClear={() => setInputs((prevState) => ({ ...prevState, email: '' }))}
                        />
                      </WrapperInput>
                      <WrapperInput>
                        <InputMack
                          mask={'+ 9(999) -999-99-99'}
                          name={'phone'}
                          isClear
                          placeholder={'Телефон'}
                          value={inputs.phone}
                          onChange={(e) => {
                            setInputs((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                                .replaceAll('-', '')
                                .replaceAll('_', '')
                                .replaceAll('+', '')
                                .replaceAll('(', '')
                                .replaceAll(')', '')
                                .trim(),
                            }));
                          }}
                          onClear={() => setInputs((prevState) => ({ ...prevState, phone: '' }))}
                        />
                      </WrapperInput>
                      <WrapperInput>
                        <Input
                          type={'password'}
                          name={'password'}
                          isClear
                          placeholder={'Пароль'}
                          value={inputs.password}
                          onChange={handleOnChange}
                          onClear={() => setInputs((prevState) => ({ ...prevState, password: '' }))}
                        />
                      </WrapperInput>
                    </Block>
                    <Checkbox
                      label={'Соглашаюсь с условиями заказа услуг'}
                      checked={inputs.remember}
                      onChange={() => setInputs((prevState) => ({ ...prevState, remember: !prevState.remember }))}
                    />
                    <WrapperButton>
                      <DefaultButton disabled={!validBtn} onClick={handleClickRegister}>
                        Получить код
                      </DefaultButton>
                    </WrapperButton>
                  </Body>
                  <BLockText>
                    <Text>
                      Заказывая услуги хостинга, вы соглашаетесь с публичной Офертой и с Условиями предоставления услуг.
                    </Text>
                    <Text>
                      Регистрируясь, вы даете согласие на обработку и хранение персональных данных ООО «Энвилхук
                      брендинг». Передаваемые персональные данные наш сервер надежно защищаются в соответствии c
                      Федеральным законом Российской Федерации No152-ФЗ «О персональных данных».
                    </Text>
                  </BLockText>
                </>
              )}
            </>
          )}
        </>
      </Content>
    </LayoutAuth>
  );
};

const SpaceBody = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextEmail = styled.div`
  margin-top: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 16px;
  width: 320px;
`;
const BLockText = styled.div`
  margin-top: 50px;
`;
const RemindButton = styled.div`
  width: 200px;
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 8px;
  width: 322px;
`;

const WrapperButton = styled.div`
  margin-top: 16px;
  width: 128px;
`;
const Block = styled.div`
  margin: 16px 0;
`;
const WrapperInput = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Body = styled.div``;
const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
