import { FilterDataProps } from 'components/filters/filter';
import { DataSort } from 'components/filters/sorting';
import { MultiFilterDataProps } from '../../components/filters/multiFilter';

export const initFilters: MultiFilterDataProps = {
  1: {
    id: 1,
    active: false,
    label: 'Дата рождения',
    value: '',
    query: 'birthdayAt',
    type: 'DATE',
  },
  2: {
    id: 2,
    active: false,
    label: 'Имя',
    value: '',
    query: 'name',
    type: 'INPUT',
  },
  3: {
    id: 3,
    active: false,
    label: 'Фамилия',
    value: '',
    query: 'surname',
    type: 'INPUT',
  },
  4: {
    id: 4,
    active: false,
    label: 'Отчество',
    value: '',
    query: 'patronymic',
    type: 'INPUT',
  },
  5: {
    id: 5,
    active: false,
    label: 'Пол',
    value: '',
    query: 'gender',
    type: 'SELECTOR',
  },
  6: {
    id: 6,
    active: false,
    label: 'Услуга',
    value: '',
    query: 'service',
    type: 'INPUT',
  },
};

export const dataSorting: DataSort[] = [
  {
    id: 1,
    label: 'Имя',
    value: 'name',
  },
  {
    id: 2,
    label: 'Фамилия',
    value: 'surname',
  },
  {
    id: 3,
    label: 'Отчество',
    value: 'patronymic',
  },
  {
    id: 4,
    label: 'Дата рождения',
    value: 'birthday_at',
  },
  {
    id: 5,
    label: 'Количество приемов',
    value: 'todolist',
  },
  {
    id: 6,
    label: 'Пол',
    value: 'gender',
  },
  {
    id: 7,
    label: 'Причина обращения',
    value: 'reason',
  },
  {
    id: 8,
    label: 'Услуга',
    value: 'comment',
  },
];

export const getActiveFilters = (data: FilterDataProps[]) => {
  return data.reduce((acc, item) => {
    if (item.active) {
      return { ...acc, [item.value]: item.value };
    } else return acc;
  }, {});
};

export const createData = (data?: Record<string, any>) => {
  return {
    gender: data?.gender,
    surname: data?.surname,
    name: data?.name,
    patronymic: data?.patronymic,
    birthday_at: data?.birthday_at,
    profession: data?.profession,
    phone: data?.phone,
    email: data?.email,
    terminal: data?.terminal,
    channel: data?.channel,
    reason: data?.reason,
    comment: data?.comment,
    series: data?.series,
    number: data?.number,
    code: data?.code,
    begin_at: data?.begin_at,
    issued_by: data?.issued_by,
    address_repeat: data?.address_repeat,
    address_register: data?.address_register,
    address_fact: data?.address_fact,
    inn: data?.inn,
    driver_license: data?.driver_license,
    car_pts: data?.car_pts,
    car_insurance: data?.car_insurance,
    car_number: data?.car_number,
    snils: data?.snils,
    policy_oms: data?.policy_oms,
    policy_dms: data?.policy_dms,
    insurance_name: data?.insurance_name,
    notification_disable: data?.notification_disable,
  };
};
export const updateData = (data?: Record<string, any>) => {
  return {
    id: data?.id,
    gender: data?.gender,
    surname: data?.surname,
    name: data?.name,
    patronymic: data?.patronymic,
    birthday_at: data?.birthday_at,
    profession: data?.profession,
    phone: data?.phone,
    email: data?.email,
    terminal: data?.terminal,
    channel: data?.channel,
    reason: data?.reason,
    comment: data?.comment,
    series: data?.series,
    number: data?.number,
    code: data?.code,
    begin_at: data?.begin_at,
    issued_by: data?.issued_by,
    address_repeat: data?.address_repeat,
    address_register: data?.address_register,
    address_fact: data?.address_fact,
    inn: data?.inn,
    driver_license: data?.driver_license,
    car_pts: data?.car_pts,
    car_insurance: data?.car_insurance,
    car_number: data?.car_number,
    snils: data?.snils,
    policy_oms: data?.policy_oms,
    policy_dms: data?.policy_dms,
    insurance_name: data?.insurance_name,
    notification_disable: data?.notification_disable,
  };
};
