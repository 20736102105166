import React, { useContext } from 'react';
import { Checkbox } from '@src/components/checkbox';
import style from './ItemContent.module.scss';
import cn from 'classnames';
import { TreeContext } from '@src/pages/recordingPriority/tabs/appointment-flowchart-builder/tree/TreeContext';

enum EType {
  SelectBranch = 'SelectBranch',
  SelectService = 'SelectService',
  SelectTime = 'SelectTime',
  SelectSpecialist = 'SelectSpecialist',
  InputData = 'InputData',
  Delivery = 'Delivery',
  SelectPayment = 'SelectPayment',
}

type TProps = {
  item: any;
  handleOptionChange: any;
}

function getItemStepNumber(scheme: Record<any, any>[], currentNodeId): number {
  let result= 0;
  let step = 1;

  const recourse = (scheme: Record<any, any>[]) => {
    for (let i = 0; i < scheme.length; i++) {
      const node = scheme[i];

      if (node.id === currentNodeId) result = step;
    }

    for (let i = 0; i < scheme.length; i++) {
      const node = scheme[i];

      if (node.children.length) {
        step += 1;
        recourse(node.children);
      }
    }
  }

  recourse(scheme);

  return result;
}

export const ItemContent: React.FC<TProps> = (props: any) => {
  const { item, handleOptionChange } = props;
  const title: Record<EType, string> = {
    [EType.SelectBranch]: 'Выбор филиала',
    [EType.SelectService]: 'Выбор услуги',
    [EType.SelectTime]: 'Выбор времени',
    [EType.SelectSpecialist]: 'Выбор специалиста',
    [EType.InputData]: 'Ввод данных',
    [EType.Delivery]: 'Доставка',
    [EType.SelectPayment]: 'Выбор оплаты',
  };
  const { openModal, state } = useContext(TreeContext);

  function openModalSettings() {
    if (item.type === EType.SelectService) {
      openModal('selectService');
    }
  }

  const withOptions = [EType.SelectService].includes(item.type);
  const scheme = state.botScheme.scheme;
  const stepNumber = getItemStepNumber(scheme, item.id);

  return (
    <div className={style.itemContent}>
      <div className={style.depthWrapper}>
        <span className={style.depth}>{stepNumber}</span>
      </div>

      <div className={style.labelWrapper}>
        <span
          className={cn([style.label, { [style.label_withOptions]: withOptions }])}
          onClick={openModalSettings}>{title[item.type]}
        </span>
      </div>

      <div className={style.checkBoxItem}>
        <Checkbox
          checked={item.options.required}
          onChange={(e) => {
            item.options.required = e.target.checked;
            handleOptionChange(item);
          }}
        />
      </div>

      <div className={style.checkBoxItem}>
        <Checkbox
          checked={item.options.isActive}
          onChange={(e) => {
            item.options.isActive = e.target.checked;
            handleOptionChange(item);
          }}
        />
      </div>
    </div>
  );
};
