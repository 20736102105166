import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';

export interface EntryState {
    is_active?: boolean | null;
    status_id?: number | null;
    client_id?: number | null;
    specialist_id?: number | null;
    service_id?: number | null;
    workspace_id?: number | null;
    amount?: string | null;
    paid?: string | null;
    reason?: string | null;
    comment?: string | null;
    date_at?: string | null;
    time_at?: string | null;
}

const initialState: EntryState = {
    is_active: null,
    status_id: null,
    client_id: null,
    specialist_id: null,
    service_id: null,
    workspace_id: null,
    amount: null,
    paid: null,
    reason: null,
    comment: null,
    date_at: null,
    time_at: null,
}

export const entrySlice = createSlice({
    name: 'entry',
    initialState,
    reducers: {
        resetEntry: () => initialState,
        setEntry: (state, action: PayloadAction<EntryState>) => {
            state = initialState;
        },
        setSpecialistId: (state, action: PayloadAction<number | null>) => {
            state.specialist_id = action.payload;
        },
        setServiceId: (state, action: PayloadAction<number | null>) => {
            state.service_id = action.payload;
        },
        setWorkspaceId: (state, action: PayloadAction<number | null>) => {
            state.workspace_id = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.is_active = action.payload;
        },
        setClientId: (state, action: PayloadAction<number | null>) => {
            state.client_id = action.payload;
        },
        setStatusId: (state, action: PayloadAction<number | null>) => {
            state.status_id = action.payload;
        },
        setAmount: (state, action: PayloadAction<string | null>) => {
            state.amount = action.payload;
        },
        setPaid: (state, action: PayloadAction<string | null>) => {
            state.paid = action.payload;
        },
        setReason: (state, action: PayloadAction<string | null>) => {
            state.reason = action.payload;
        },
        setComment: (state, action: PayloadAction<string | null>) => {
            state.comment = action.payload;
        },
        setDate: (state, action: PayloadAction<string | null>) => {
            state.date_at = action.payload;
        },
        setTime: (state, action: PayloadAction<string | null>) => {
            state.time_at = action.payload;
        },
    },
});

export const { 
    resetEntry,
    setEntry, 
    setSpecialistId,
    setServiceId,
    setActive,
    setClientId,
    setStatusId,
    setWorkspaceId,
    setAmount,
    setPaid,
    setReason,
    setComment,
    setDate,
    setTime,
} = entrySlice.actions;

export default entrySlice.reducer;

//выбранное время в календаре
export const entrySelector = {
    entry: (s: RootState): EntryState => s.entry,
    date_at: (s: RootState): string | null | undefined => s.entry.date_at,
    time_at: (s: RootState): string | null | undefined => s.entry.time_at,
};