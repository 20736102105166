import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMouseDown } from 'core/hooks';

export type FilterDataComplexProps = {
  id: number;
  active: boolean;
  value: string | number;
};

export type FilterParentComplexProps = {
  id: number;
  value: string | number;
  active: boolean;
  items: FilterDataComplexProps[];
};

type FilterComplexProps = {
  data: FilterParentComplexProps[];
  onChange: (data: FilterParentComplexProps[]) => void;
};

export const FilterComplex: React.FC<FilterComplexProps> = ({ data }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [itemFilter, setItemFilter] = useState<FilterParentComplexProps>();
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (itemFilter) {
      const item = data.find((i) => i.id === itemFilter.id);
      item && setItemFilter(item);
    }
  }, [data]);
  useMouseDown(() => setShowList(false), listRef, inputRef);

  return (
    <Content active={showList}>
      {/*<Header ref={inputRef} onClick={handleClickSorting}>
        <WrapperIcon>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>Фильтр</TitleFilter>
        {!!counts && <CountFilter>{counts}</CountFilter>}
      </Header>
      <ContentPopUpList ref={listRef} active={showList} padding={!!itemFilter?.items?.length}>
        {itemFilter?.items?.length ? (
          <ItemsBlock>
            <WrapperValueItems>
              <WrapperIconItems onClick={() => setItemFilter(undefined)}>
                <IconArrowLeftFilter />
              </WrapperIconItems>
              <ValueItems>{itemFilter.value}</ValueItems>
            </WrapperValueItems>
            {itemFilter?.items?.map((item) => (
              <WrapperItemItems key={item.id}>
                <Checkbox
                  color={'#DFE1E4'}
                  title={item.value.toString()}
                  active={item.active}
                  setActive={handleClickChecked(item.id)}
                />
              </WrapperItemItems>
            ))}
          </ItemsBlock>
        ) : (
          data?.map((item) => (
            <ItemContentPopUpList key={item.id} onClick={handleClickItems(item)}>
              {item.value}
            </ItemContentPopUpList>
          ))
        )}
      </ContentPopUpList>*/}
    </Content>
  );
};
const Content = styled.div<{ active: boolean }>`
  width: 100%;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
