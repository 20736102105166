import createRequestRest from '@src/api/createRequestRest';
import { kanbanSlice } from '../../slices/kanban/kanbanSlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(kanbanSlice.actions.updateSortTaskSuccess(response));
  payload?.payload?.success?.();
  yield;
}

function* prepareFailure(res: any, payload: any) {
  payload?.payload?.failure?.();
  yield;
}

export const updateSortTask = () => {
  return createRequestRest({
    url: '/api/v1/todolist/changeSort',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.updateSortTaskRequest,
    method: 'post',
  });
};
