import React, { useMemo, useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import styled, { css } from 'styled-components';
import { IconConfirmation, IconDiscount, IconEditUser, IconEmailUser, IconMessages, IconUser } from '../../../icons';
import { transformKart } from '../utils';
import { ModalChangeClient } from '@src/features/modals/modalChangeClient';
import { useDispatch } from 'react-redux';
import { clientsSlice } from '@redux/slices/clients/clientsSlice';
import { kanbanSlice } from '@redux/slices/kanban/kanbanSlice';

export const ItemCard: React.FC<any> = ({ data, index, onEdit }) => {
  const dispatch = useDispatch();
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);
  const localData = useMemo(() => transformKart(data), [data]);

  const requestDataClient = (id?: string) => () => {
    if (id) {
      dispatch(
        clientsSlice.actions.getClientRequest({
          payload: {
            data: {
              id,
            },
            success: () => setShowModalClient(true),
          },
        }),
      );
    }
  };

  const updateRequestData = () => {
    dispatch(kanbanSlice.actions.getAllTodoListRequest());
  };

  function editHandle() {
    dispatch(
      kanbanSlice.actions.getCardTodoListRequest({
        payload: {
          data: { id: data.id },
          success: () => {
            onEdit();
          },
        },
      }),
    );
  }

  return (
    <>
      <Draggable draggableId={localData.id.toString()} index={index}>
        {(provided: any, snapshot: any) => {
          return (
            <Content
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Header>
                <Block>
                  <BlockTitle>
                    <Text>{localData.time}</Text>
                    <StyleDelimiter />
                    <Text>{localData.cabinet}</Text>
                  </BlockTitle>
                  <BlockIcons>
                    <IconButton disabled onClick={() => setShowModalConfirm(true)}>
                      <IconConfirmation />
                    </IconButton>
                    <IconButton disabled>
                      <IconDiscount />
                    </IconButton>
                    <IconButton disabled>
                      <IconMessages />
                    </IconButton>
                  </BlockIcons>
                </Block>
                <ServiceTitle>{localData.service}</ServiceTitle>
                <Text>{localData.doctor}</Text>
              </Header>

              <Footer>
                <BlockUser>
                  <UserTitle>{localData.client}</UserTitle>
                  <Phone>{localData.phone}</Phone>
                </BlockUser>

                <UserBlockButton>
                  <WrapperIconUser onClick={requestDataClient(data?.client?.client_id)}>
                    <IconUser />
                  </WrapperIconUser>

                  <WrapperIconUser disabled onClick={() => setShowModalEmail(true)}>
                    <IconEmailUser />
                  </WrapperIconUser>

                  <WrapperIconUser onClick={editHandle}>
                    <IconEditUser />
                  </WrapperIconUser>
                </UserBlockButton>
              </Footer>
            </Content>
          );
        }}
      </Draggable>
      <ModalChangeClient
        setShowModal={setShowModalClient}
        showModal={showModalClient}
        updateRequestData={updateRequestData}
      />
    </>
  );
};
const WrapperIconUser = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  height: 22px;
  width: 22px;
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 4px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;
        background: var(--color-grey);

        svg {
          path,
          rect {
            stroke: var(--color-black-grey);
          }
        }
      `;
    }
  }}
`;
const UserBlockButton = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
const BlockUser = styled.div`
  display: flex;
  align-items: center;
`;
const Phone = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #6b6f76;
`;
const UserTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6b6f76;
  margin-right: 8px;
`;
const ServiceTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #3d4148;
`;
const IconButton = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 4px;
  }

  position: relative;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;

        &:after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          background: rgba(0, 0, 0, 0.07);
        }
      `;
    }
  }}
`;
const BlockIcons = styled.div`
  display: flex;
  align-items: center;
`;
const BlockTitle = styled.div`
  display: flex;
  align-items: center;
`;
const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyleDelimiter = styled.div`
  width: 16px;
  height: 20px;
  position: relative;

  &:before {
    content: '';
    height: 2px;
    width: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #6b6f76;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #282a2f;
  opacity: 0.5;
`;
const Header = styled.div`
  padding: 12px 15px;
  border-bottom: 1px solid #dfe1e4;
`;
const Footer = styled.div`
  padding: 8px 15px 12px;
`;
const Content = styled.div`
  background: #ffffff;
  border-radius: 8px;
  position: relative;

  &:before {
    content: '';
    height: 100%;
    width: 4px;
    background: #ffb5b5;
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`;
