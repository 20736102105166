import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { SpecialistProps } from './types';
import { PersonalDataProps } from '../../../pages/specialist/tabsModal/types';

type SpecialistStateProps = {
  specialists: SpecialistProps[];
  appointment: any;
  specialist: PersonalDataProps | null;
  schedule: Record<string, any>;
  calendar: Record<string, any>[];
  workplaces: Record<string, any>[];
  count: number;
  search: Record<string, any>[];
  specialistId: number | null; // используется когда для разных случаев нужно запомнить id текущего специалиста
};

const initialState: SpecialistStateProps = {
  appointment: [],
  specialists: [],
  count: 0,
  specialist: null,
  schedule: {},
  search: [],
  specialistId: null,
  calendar: [],
  workplaces: [],
};

export const specialistSlice = createSlice({
  name: 'specialist',
  initialState,
  reducers: {
    //Сброс данных активного специалиста
    //---------------------------------
    resetData: (state: SpecialistStateProps) => {
      state.specialist = null;
    },
    //---------------------------------
    resetSearch: (state: SpecialistStateProps) => {
      state.search = [];
    },
    //Поиск услуг
    //---------------------------------
    searchServiceRequest: (state: SpecialistStateProps, action) => {
      //
    },
    searchServiceSuccess: (state: SpecialistStateProps, action) => {
      state.search = action.payload?.services?.data;
    },
    //Получение расписания для специалистов
    //---------------------------------
    getScheduleRequest: (state: SpecialistStateProps, action) => {
      //
    },
    getScheduleSuccess: (state: SpecialistStateProps, action) => {
      state.schedule = action.payload;
    },

    //Получение всех специалистов
    //---------------------------------
    getAllSpecialistRequest: (state: SpecialistStateProps, action) => {
      //
    },
    getAllSpecialistSuccess: (state: SpecialistStateProps, action) => {
      state.specialists = action.payload?.data;
      state.count = action.payload?.count;
    },

    //get list of available times to appointments
    getSpecialistAppointmentTimesRequest: (state: SpecialistStateProps, action) => {
      //
    },
    getSpecialistAppointmentTimesSuccess: (state: SpecialistStateProps, action) => {
    },

    //Получение специалиста по Id
    //---------------------------------
    getSpecialistIdRequest: (state: SpecialistStateProps, action) => {
      //
    },
    getSpecialistIdSuccess: (state: SpecialistStateProps, action) => {
      state.specialist = action.payload;
    },
    //Создание специалиста
    //---------------------------------
    postCreateSpecialistRequest: (state: SpecialistStateProps, action) => {
      //
    },
    postCreateSpecialistSuccess: (state: SpecialistStateProps, action) => {
      state.specialist = action.payload;
    },
    //Обновление специалиста
    //---------------------------------
    postUpdateSpecialistRequest: (state: SpecialistStateProps, action) => {
      //
    },
    postUpdateSpecialistSuccess: (state: SpecialistStateProps, action) => {
      state.specialist = action.payload;
    },
    //Удаление специалиста
    //---------------------------------
    postDeleteSpecialistRequest: (state: SpecialistStateProps, action) => {
      //
    },
    postDeleteSpecialistSuccess: (state: SpecialistStateProps, action) => {
      // state.specialist = action.payload;
    },

    // Запомнить или сбросить (передать null) id специалиста
    setSpecialistId: (state: SpecialistStateProps, action) => {
      state.specialistId = action.payload;
    },
    // Добавление записи в календарь специалиста
    //---------------------------------
    postCreateSpecialistCalendarRequest: (state: SpecialistStateProps, action) => {
      //
    },
    postCreateSpecialistCalendarSuccess: (state: SpecialistStateProps, action) => {
      state.calendar = action.payload?.data;
    },
    // Удаление записи из календаря специалиста
    //---------------------------------
    postDeleteSpecialistCalendarRequest: (state: SpecialistStateProps, action) => {
      //
    },
    postDeleteSpecialistCalendarSuccess: (state: SpecialistStateProps, action) => {
      //
    },
  },
});

export const specialistSelector = {
  specialists: (s: RootState) => s.specialist.specialists,
  specialist: (s: RootState) => s.specialist.specialist,
  count: (s: RootState) => s.specialist.count,
  schedule: (s: RootState) => s.specialist.schedule,
  calendar: (s: RootState) => s.specialist.calendar,
  search: (s: RootState) => s.specialist.search,
  specialistId: (s: RootState) => s.specialist.specialistId,
};
