import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconClose, IconEmailUser, IconPlus, IconRub, IconSearch, IconUser } from '@src/icons';
import { Service } from './service';
import { InputCurrency } from '@src/components/inputCurrency';
import { useTabsContext } from '@src/components/entry-modal/context/context';
import { useSearchClients } from '@src/pages/kanban/hooks/useSearchClients';
import { SearchClientsSelector } from '@src/features/searchClientsSelector';
import { v4 as idV4 } from 'uuid';
import { formatValueNumber, valid } from '@src/core/utils';
import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { kanbanSelector } from '@redux/slices/kanban/kanbanSlice';
import { transformClient } from '@src/pages/kanban/hooks/helpers';
import { clientsSlice } from '@redux/slices/clients/clientsSlice';
import { ModalChangeClient } from '@src/features/modals/modalChangeClient';
import { StatusPaid } from '@src/components/entry-modal/components/tabs-content/entry-content/status-paid/StatusPaid';

export const EntryContent: React.FC<any> = (props) => {
  const { data: dataColumn, setChanged, addFormRef, removeFormRef } = props;
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [formsIds, setFormsIds] = useState<string[]>([]);

  const addNewService = () => {
    setFormsIds([...formsIds, idV4()]);
  };

  useEffect(() => {
    formsIds.length === 0 && addNewService();
  }, []);


  const [loading, setLoading] = useState(true);
  const [searchClientsValue, setSearchClientsValue] = useState('');
  const [validClient, setValidClient] = useState(true);
  const [showModalClient, setShowModalClient] = useState(false);
  const { state: stateTabs, setState: setStateTabs, actions } = useTabsContext();
  const noClientError = stateTabs.entry.errors.client;
  const editTodo = useAppSelector(kanbanSelector.activeCard);

  const handledValidClient = (value: boolean) => {
    setValidClient(value);
  };

  const {
    handledOnChangeSearch,
    handleClickItem,
    activeClient,
    clearClient,
    requestData,
    setActiveClient,
  } =
    useSearchClients({
      setLoading,
      setSearch: setSearchClientsValue,
      handledValidClient,
    });


  useEffect(() => {
    if (activeCard) {
      setActiveClient(transformClient(activeCard.client));
    }
  }, [activeCard]);

  useEffect(() => {
    actions.setClient(activeClient);
  }, [activeClient]);

  useEffect(() => {
    actions.setColumn(dataColumn);
  }, [dataColumn]);

  const handledOnFocus = () => {
    setLoading(true);
  };

  const handledRemoveService = (removeId: string) => () => {
    setFormsIds(formsIds.filter((id) => id !== removeId));
    removeFormRef(removeId);
  };

  const requestDataClient = (id?: string) => () => {
    if (id) {
      dispatch(
        clientsSlice.actions.getClientRequest({
          payload: {
            data: {
              id,
            },
            success: () => setShowModalClient(true),
          },
        }),
      );
    }
  };

  const updateRequestData = (data?: Record<string, any>) => {
    if (data) setActiveClient(transformClient(data));
  };

  return (
    <BlocContentModel>
      <Left>
        <Head>
          {activeClient ? (
            <>
              <SearchIcon>{activeClient.reduction}</SearchIcon>

              <WrapperInputs>
                <SearchInput>
                  <FullNameClientActive>{activeClient.fullName || activeClient.name}</FullNameClientActive>

                  <WrapperIcons>
                    <WrapperIconClient disabled>
                      <IconEmailUser />
                    </WrapperIconClient>

                    <WrapperIconClient onClick={requestDataClient(activeClient.id)}>
                      <IconUser />
                    </WrapperIconClient>

                    <WrapperIconClient onClick={() => {
                      clearClient();
                      setChanged(true);
                    }}>
                      <IconClose />
                    </WrapperIconClient>
                  </WrapperIcons>
                </SearchInput>

                <DataUser>
                  <WrapperInput>{activeClient?.phone || ''}</WrapperInput>
                  <WrapperInput>{activeClient?.birthday_at || ''}</WrapperInput>
                </DataUser>
              </WrapperInputs>
            </>
          ) : (
            <>
              <SearchIcon>
                <IconSearch />
              </SearchIcon>

              <WrapperInputs>
                <SearchInput>
                  <SearchClientsSelector
                    valid={validClient && !noClientError}
                    onFocus={handledOnFocus}
                    onBlur={() => {
                      setValidClient(!!activeClient);
                      setLoading(false);
                    }}
                    value={searchClientsValue || ''}
                    loading={loading}
                    placeholder={'Поиск клиента'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handledOnChangeSearch(e);
                      setChanged(true);
                    }}
                    data={requestData}
                    handleClickItem={(data: Record<string, any>) => {
                      handleClickItem(data);
                      setChanged(true);
                    }}
                    isClear
                    onClear={() => {
                      clearClient();
                      setChanged(true);
                    }}
                  />
                </SearchInput>
              </WrapperInputs>
            </>
          )}
        </Head>

        {formsIds.map((id, i) => (
          <WrapperServices key={id}>
            <Service
              innerRef={(ref) => addFormRef(id, ref)}
              countServices={formsIds.length}
              onClose={handledRemoveService(id)}
              //in the first form print service item that need edit if exists
              editTodo={i === 0 ? editTodo : null}
            />
          </WrapperServices>
        ))}

        <AddService onClick={addNewService}>
          <WrapperIcon>
            <IconPlus />
          </WrapperIcon>
          <TitleService>Добавить услугу</TitleService>
        </AddService>
      </Left>

      <StatusPaid dataColumn={dataColumn} />

      <ModalChangeClient
        updateRequestData={updateRequestData}
        showModal={showModalClient}
        setShowModal={setShowModalClient}
      />
    </BlocContentModel>
  );
};

const WrapperServices = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const WrapperIconClient = styled.div<{ disabled?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-left: 20px;

    svg {
      path {
        stroke: #d23333;
      }
    }
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: auto;
        background: var(--color-grey);

        svg {
          path,
          rect {
            stroke: var(--color-black-grey);
          }
        }
      `;
    }
  }}
`;

const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`;

const FullNameClientActive = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const BlocContentModel = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 8px;
`;

const TitleService = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #6471e0;
`;

const AddService = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;

const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    path {
      stroke: #6471e0;
    }
  }
`;

const WrapperInput = styled.div`
  font-size: 13px;
  color: #6b6f76;
  width: 139px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const DataUser = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperInputs = styled.div``;

const SearchInput = styled.div`
  width: 410px;
  display: flex;
`;

const Head = styled.div`
  width: 460px;
  display: flex;
  margin-bottom: 16px;
  position: relative;
`;

const SearchIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background: #eff1f4;
`;

const Left = styled.div`
  padding: 20px 16px;
`;
