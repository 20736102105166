import React from 'react';
import styled from 'styled-components';
import { ImCopy } from 'react-icons/im';
import { Checkbox } from 'components/checkbox';
import { IconPencil, IconPlus, IconTrash } from 'icons';

type ControlProps = {
  data?: Record<string, any>;
  hiddenPlus?: boolean;
  hiddenTrash?: boolean;
  hiddenCopy?: boolean;
  disabled?: boolean;
  active: boolean;
  setActive: () => void;
  setClickPencil: () => void;
  setClickPlus: () => void;
  setClickTrash: () => void;
  setClickCopy?: () => void;
};

export const Control: React.FC<ControlProps> = ({
  data,
  hiddenPlus = true,
  hiddenTrash = false,
  hiddenCopy = false,
  disabled,
  active,
  setActive,
  setClickPencil,
  setClickPlus,
  setClickTrash,
  setClickCopy,
}) => {
  const title = data?.type === 'service' ? 'Активная услуга' : 'Активная категория';

  return (
    <Content>
      <Item>
        <Checkbox disabled={disabled} checked={active} onChange={setActive} label={title} />
      </Item>

      <Item onClick={setClickPencil} title={'Редактировать услугу'}>
        <IconPencil />
      </Item>

      {!hiddenPlus && (
        <Item onClick={setClickPlus} title={'Добавить услугу'}>
          <IconPlus />
        </Item>
      )}

      {!hiddenCopy && (
        <ItemCopy onClick={setClickCopy} title={'Скопировать услугу'}>
          <ImCopy />
        </ItemCopy>
      )}

      {!hiddenTrash && (
        <Item onClick={setClickTrash} title={'Удалить услугу'}>
          <IconTrash />
        </Item>
      )}
    </Content>
  );
};

const Item = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-left: 5px;
  }
`;
const ItemCopy = styled(Item)`
  svg {
    height: 14px;
    width: 14px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
