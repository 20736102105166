import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowDown, IconEyeSlash, IconEye } from 'icons';
import { Control } from './Control';
import { ItemService } from './ItemService';
import { serviceSlice } from '@redux/slices/service/serviceSlice';
import { useAppDispatch } from '@src/core/hooks';

export const ItemCategory: React.FC<any> = (props) => {
  const {
    data,
    // changeService,
    editService,
    editCategory,
    deleteService,
    copyService,
    addServiceCategory,
    left = 0,
    level = 1,
  } = props;
  const activeCount = data?.services?.filter(child => child.is_active).length || 0;
  const [isShowChild, setShowChild] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handledClickTitle = (id: string | number) => () => {
    if (data.services.length > 0) {
      setShowChild(!isShowChild);
    }
  };
  const valueLeft = left + 25;

  const changeActive = () => {
    const isActive = isCategoryActive();
    const ids = data.services.map((item) => item.id);

    dispatch(serviceSlice.actions.setActiveRequest({ data: { id: ids, active: !isActive } }));

    dispatch(serviceSlice.actions.updateService({
      ids,
      update: {
        is_active: !isActive,
      },
    }));
  };

  function isCategoryActive() {
    if (data.services.length === 0) return true;

    let isCategoryActive = false;

    for (const service of data.services) {
      if (service.is_active) {
        isCategoryActive = true;
        break;
      }
    }

    return isCategoryActive;
  }

  return (
    <>
      <Content left={valueLeft}>
        <WrapperTitle>
          <WrapperIcon onClick={handledClickTitle(data?.id)} show={isShowChild}>
            <IconArrowDown />
          </WrapperIcon>

          <Title onClick={() => editCategory(data)}>{data?.title}</Title>

          <Count>{`(${activeCount} / ${data.services.length})`}</Count>

          <IconShell>
            {isCategoryActive() ? <IconEye title={'Услуга активна'} /> :
              <IconEyeSlash title={'Услуга неактивна'} />}
          </IconShell>
        </WrapperTitle>

        <WrapperControl>
          <Control
            data={data}
            hiddenCopy={false}
            hiddenPlus={false}
            active={isCategoryActive()}
            setActive={changeActive}
            setClickPencil={() => editCategory(data)}
            setClickPlus={() => addServiceCategory(data, level)}
            setClickTrash={() => deleteService(data)}
            setClickCopy={() => copyService(data)}
          />
        </WrapperControl>
      </Content>

      {isShowChild && data.services?.map((item: Record<string, any>) => (
        <ItemService
          key={item.id}
          level={level + 1}
          data={item}
          left={valueLeft}
          changeService={editService}
          deleteService={deleteService}
          // changeActive={changeActive}
          addServiceCategory={addServiceCategory}
          copyService={copyService}
        />
      ))}
    </>
  );
};

const Count = styled.div`
  font-size: 12px;
  color: var(--color-black-grey);
  padding-left: 5px;
`;

const IconShell = styled.div`
  margin-left: 10px;
  color: var(--color-blue);

  svg {
    width: 14px;
    height: 14px;
  }
`;
const WrapperIcon = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.33s ease-in-out;
  ${({ show }) => {
    if (show) {
      return css`
        svg {
          transform: rotate(-90deg);
          transition: all 0.33s ease-in-out;
        }
      `;
    } else {
      return css`
        transform: rotate(0deg);
      `;
    }
  }}
`;
const WrapperControl = styled.div`
  padding-right: 9px;
  opacity: 0;
`;
const WrapperTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  user-select: none;
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);

  &:hover {
    text-decoration: underline;
  }
`;
const Content = styled.div<{ left?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ left }) => {
    return css`
      padding: 10px 0 10px ${left}px;
    `;
  }}
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-light);
  }

  &:hover {
    background: #f1f3f8;
  }

  &:hover ${WrapperControl} {
    opacity: 1;
  }
`;
