import React from 'react';
import styled from 'styled-components';
import { IconPlus } from 'icons';

type AddKartProps = {
  onClick: () => void;
};

export const AddCard: React.FC<AddKartProps> = ({ onClick }) => {
  return (
    <Content onClick={onClick}>
      <WrapperIcon>
        <IconPlus />
      </WrapperIcon>
      <Title>Добавить запись</Title>
    </Content>
  );
};
const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282a2f;
  margin-left: 5px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  cursor: pointer;
  padding: 8px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;
