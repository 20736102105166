import { createSlice } from '@reduxjs/toolkit';

type TState = {
  telegramSettings: Record<string | number, any>;
  scheme: any;
  settings: any;
};

const initialState: TState = {
  telegramSettings: [],
  scheme: [],
  settings: {},
};

export const onlineEntrySlice = createSlice({
  name: 'recordPriority',
  initialState,
  reducers: {
    //получение настроек бота
    getTelegramSettingsRequest: (state: TState) => {
      //
    },
    //успех получения
    getTelegramSettingsSuccess: (state: TState, action) => {
      state.telegramSettings = action?.payload;
    },
    //обновление настроек бота
    postUpdateTelegramSettingsRequest: (state: TState, action) => {
      state.telegramSettings = action.payload;
    },
    //успех обновления
    postUpdateTelegramSettingsSuccess: (state: TState, action) => {
      state.telegramSettings = action?.payload;
    },
    //обновление очередности записи в настройках бота
    postUpdateTelegramSettingsSequencesRequest: (state: TState, action) => {
      state.telegramSettings.sequences = action.payload;
    },
    postUpdateTelegramSettingsSequencesSuccess: (state: TState, action) => {
      state.telegramSettings.sequences = action?.payload;
    },

    getOnlineEntryRequest: (state, action) => {
    },
    getOnlineEntrySuccess: (state, action) => {
      state.scheme = action?.payload.scheme;
      state.settings = action?.payload.settings;
    },

    updateSchemeRequest: (state, action) => {
    },
    updateSchemeSuccess: (state, action) => {
    },

    setSiteDomainRequest: (state, action) => {
    },
    setSiteDomainSuccess: (state, action) => {
      state.settings.domain = action.payload.domain;
    },

    updateContactFieldsRequest: (state, action) => {
    },
    updateContactFieldsSuccess: (state, action) => {
    },
  },
});
