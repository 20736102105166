import createRequestRest from '@src/api/createRequestRest';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';

function* prepareRequest(payload: Record<string, any>) {
  return payload.data;
  yield;
}

export const contactFields = () => {
  return createRequestRest({
    url: '/api/v1/online-entry/contact-fields',
    method: 'patch',
    prepareRequest,
    action: onlineEntrySlice.actions.updateContactFieldsRequest,
  });
};
