import React from 'react';
import styled from 'styled-components';
import { IconChecked } from '../../icons';
import style from './index.module.scss';

type CheckboxProps = {
  disabled?: boolean;
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color?: string;
  name?: string;
  id?: string;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { color = '#6471E0' } = props;
  return (
    <label className={style.label}>
      <input
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        className={style.input}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
      />

      <Content disabled={props.disabled} color={color}>
        {props.checked && <Icon />}
      </Content>

      {props.label ? <Caption>{props.label}</Caption>: null}
    </label>
  );
};
const Icon = styled(IconChecked)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Caption = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  margin-left: 5px;
  white-space: nowrap;
`;

const Content = styled.div<{ color: string; disabled?: boolean }>`
  position: relative;
  height: 14px;
  width: 14px;
  border: ${({ color, disabled }) => (disabled ? '1px solid var(--color-grey)' : `1px solid ${color}`)};
  border-radius: 3px;
  user-select: none;
`;
