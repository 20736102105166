import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { CalendarDay } from './calendarDay';
import { useAppSelector } from 'core/hooks';
import { scheduleCalendarSelector } from '@redux/slices/schedule-calendar/scheduleCalendarSlice';

type CalendarDaysProps = {
  yearMonth: string;
}

const getMonthDates = (yearMonth: string) => {
  const dates: Moment[] = [];
  const first = moment(yearMonth).startOf('month').day() || 7;
  if (first) {
    for(let i = 0; i < first;i++) {
      if (i > 0) {
        const day = moment(yearMonth).subtract(1, 'months').endOf('month').day(i);
        dates.push(day);
      }
    }
  }
  return dates;
}

export const CalendarDays: React.FC<CalendarDaysProps> = ({yearMonth}) => {
  const [prevMonthDays, setPrevMonthDays] = useState<Moment[]>([]);
  const calendar = useAppSelector(scheduleCalendarSelector.calendar);

  useEffect(() => {
    const monthDays = getMonthDates(yearMonth);
    monthDays && setPrevMonthDays(monthDays);
  }, [yearMonth]);
  
  return (
    <Content>
      {prevMonthDays?.map?.((item: any,i: number) => <CalendarDay key={i} prev={true} day={item} />)}
      {calendar?.days?.map?.((item: any,i: number) => <CalendarDay key={i} day={item} />)}
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 0;
  width: 100%;
`;