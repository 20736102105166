import { serviceSlice } from '@redux/slices/service/serviceSlice';
import { clientsSlice } from '@redux/slices/clients/clientsSlice';
import { specialistSlice } from '@redux/slices/specialist/specialistSlice';
import { workplaceSlice } from '@redux/slices/workplace/workplaceSlice';
import { kanbanSlice } from '@redux/slices/kanban/kanbanSlice';
import { authSlice } from '@redux/slices/auth/authSlice';
import { scheduleSlice } from '@redux/slices/schedule/scheduleSlice';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { todolistSlice } from '@redux/slices/todolist/todolistSlice';
import { entrySlice } from '@redux/slices/entry/entrySlice';
import { branchSlice } from '@redux/slices/branch/branchSlice';
import { scheduleFeatureSlice } from '@redux/slices/schedule-feature/scheduleFeatureSlice';
import { scheduleCalendarSlice } from '@redux/slices/schedule-calendar/scheduleCalendarSlice';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducers = combineReducers({
  onlineEntry: onlineEntrySlice.reducer,
  auth: authSlice.reducer,
  schedule: scheduleSlice.reducer,
  kanban: kanbanSlice.reducer,
  workplace: workplaceSlice.reducer,
  specialist: specialistSlice.reducer,
  service: serviceSlice.reducer,
  clients: clientsSlice.reducer,
  todolist: todolistSlice.reducer,
  entry: entrySlice.reducer,
  branch: branchSlice.reducer,
  scheduleFeature: scheduleFeatureSlice.reducer,
  scheduleCalendar: scheduleCalendarSlice.reducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    const { routing } = state;
    state = { routing };
  }
  return rootReducers(state, action);
};
