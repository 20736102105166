import { all } from 'redux-saga/effects';
import { updateScheme } from '@redux/sagas/online-entry/updateScheme';
import { setDomain } from '@redux/sagas/online-entry/setDomain';
import { getOnlineEntry } from '@redux/sagas/online-entry/getOnlineEntry';
import { contactFields } from '@redux/sagas/online-entry/updateContactFields';

export default function* saga() {
  yield all([
    updateScheme(),
    setDomain(),
    getOnlineEntry(),
    contactFields(),
  ]);
}
