import styled from 'styled-components';

const Content = styled.div`
  padding: 24px 20px;
  background: #ffffff;
  align-items: flex-start;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
`;

export default Content;