import { formatValueNumber } from '@src/core/utils';
import { IconRub } from '@src/icons';
import { InputCurrency } from '@src/components/inputCurrency';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTabsContext } from '@src/components/entry-modal/context/context';
import { Form, Select } from 'antd';
import { Formik } from 'formik';
import { useAppSelector } from '@src/core/hooks';
import { kanbanSelector } from '@redux/slices/kanban/kanbanSlice';

export const StatusPaid: React.FC<any> = (props) => {
  const { dataColumn } = props;
  const { state: stateTabs, setState: setStateTabs, actions } = useTabsContext();
  const recordData = useMemo(() => stateTabs?.record?.data, [stateTabs?.record?.data]);
  const totalSum = 0;
  const columns = useAppSelector(kanbanSelector.columns);
  const selectStatusOptions: any = [];

  columns.map((column) => {
    selectStatusOptions.push({
      value: column.id,
      label: column.name,
    });
  });

  const initialValues = {
    column: dataColumn?.id || null,
    paid: null,
  };

  return <Formik
    initialValues={initialValues}
    innerRef={actions.setFormStatusAndPaidRef}
    onSubmit={(_, actions) => {
      actions.setSubmitting(false);
    }}
  >
    {(props) => {
      const {
        values,
        setFieldValue,
      } = props;

      return (
        <Form>
          <Right>
            <Title>Запись</Title>

            <Row>
              <TitleRow>Статус</TitleRow>

              <TypeContent>
                <Select
                  value={values.column}
                  style={{ width: '100%' }}
                  options={selectStatusOptions}
                  onChange={(value) => {
                    setFieldValue('column', value);
                  }}
                />
              </TypeContent>
            </Row>

            <Row>
              <TitleRow>Итого</TitleRow>
              <SumValue>
                <span>{formatValueNumber(totalSum || stateTabs?.record?.data?.services?.[0]?.amount)}</span> <IconRub />
              </SumValue>
            </Row>

            <Row>
              <TitleRow></TitleRow>
              <InputCurrency value={recordData?.paid}
                             onChange={(e) => setFieldValue('paid', parseInt(e.target.value) || null)} />
            </Row>
          </Right>
        </Form>
      );
    }}
  </Formik>;
};

const SumValue = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 5px;
  }

  svg {
    path {
      fill: #282a2f;
    }
  }
`;
const TypeContent = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
`;

const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 87px 1fr;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

const Title = styled.div`
  margin-bottom: 18px;
`;

const Right = styled.div`
  border-left: 1px solid #eff1f4;
  padding: 18px 28px;
  height: 100%;
`;
