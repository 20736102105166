import createRequestRest from '@src/api/createRequestRest';
import { serviceSlice } from '../../slices/service/serviceSlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(payload: any) {
  return { id: payload?.payload?.data?.id };
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.deleteServiceSuccess(payload?.payload?.data));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const deleteService = () => {
  return createRequestRest({
    url: '/api/v1/service/remove',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.deleteServiceRequest,
    method: 'delete',
  });
};
