import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { useTabsContext } from './context';
import { useAppSelector } from 'core/hooks';
import { categoryActiveServiceSelector, categoryServiceSelector } from '@redux/slices/service/serviceSlice';
import { SelectorStatic } from 'components/selectorStatic';
import { transformParentsSelect } from '../utils';
import { Textarea } from 'components/textarea';

const initValidData: Record<string, boolean> = {
  title: true,
};

export const CategoryTab: React.FC = () => {
  const activeCategory: Record<string, any> | null = useAppSelector(categoryActiveServiceSelector);
  const category = useAppSelector(categoryServiceSelector);

  const [validData, setValidData] = useState(initValidData);
  const defaultPArentCategory = { id: Symbol('id'), value: 'Без родительской категории' };

  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const data = useMemo(() => stateTabs?.category?.data, [stateTabs?.category?.data]);

  const parents = useMemo(() => {
    if (activeCategory) {
      return transformParentsSelect(category).filter((item) => item.id !== activeCategory.id);
    }
    return [];
  }, [activeCategory]);

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      category: {
        ...prev?.category,
        activeTab: 'category',
        data: { ...activeCategory, category_id: prev?.category?.data?.category_id || defaultPArentCategory.id },
      },
    }));

    return () => {
      setStateTabs?.((prev) => ({
        ...prev,
        category: {
          ...prev?.category,
          activeTab: null,
        },
      }));
    };
  }, []);

  useEffect(() => {
    if (activeCategory) {
      setStateTabs?.((prev) => ({
        ...prev,
        category: {
          ...prev?.category,
          activeTab: 'category',
          data: {
            ...activeCategory,
            category_id: activeCategory?.category?.data?.category_id || defaultPArentCategory.id,
          },
        },
      }));
    }
  }, [activeCategory]);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValidData((prev) => ({ ...prev, [e.target.name]: true }));
    setStateTabs?.((prev) => ({
      ...prev,
      category: { ...prev?.category, data: { ...prev?.category?.data, [e.target.name]: e.target.value } },
    }));
  };

  useEffect(() => {
    setStateTabs?.((prev) => ({
      ...prev,
      category: {
        ...prev?.category,
        activeTab: 'category',
        onIsValid: () => {
          const newValid = Object.entries(validData).reduce(
            (acc, [key, _]) => ({ ...acc, [key]: !!prev?.category?.data?.[key] }),
            {},
          );
          setValidData(newValid);
          //TODO refactoring
          return !Object.entries(newValid)
            .map(([_, item]) => item)
            .includes(false);
        },
      },
    }));
  }, [validData]);

  const handleChangeSelector = (data: Record<string, any>) => {
    setValidData((prev) => ({ ...prev, category_id: true }));
    setStateTabs?.((prev) => ({
      ...prev,
      category: {
        ...prev?.category,
        data: {
          ...prev?.category?.data,
          category_id: data.id,
        },
      },
    }));
  };

  return (
    <Content>
      <BlockModal>
        <WrapperSelector>
          <SelectorStatic
            key={parents.toString()}
            valueId={data?.category_id}
            valid={validData.category_id}
            placeholder='Выберите родительскую категорию'
            data={[defaultPArentCategory, ...parents]}
            onChange={handleChangeSelector}
            isClear
          />
        </WrapperSelector>
        <Input
          valid={validData.title}
          name='title'
          placeholder='Наименование категории'
          value={data?.title}
          onChange={handleChangeData}
        />
        <WrapperSelector>
          <Textarea
            onChange={handleChangeData}
            value={data?.description}
            name={'description'}
            placeholder={'Описание'}
          />
        </WrapperSelector>
      </BlockModal>
    </Content>
  );
};

const Content = styled.div``;

const WrapperSelector = styled.div`
  margin: 16px 0;
`;

const BlockModal = styled.div`
  width: 492px;
  padding: 20px 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
