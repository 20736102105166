import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ButtonPros } from 'core/types';
import { IconCloud, IconPrinter, IconTrash } from 'icons';
import { InputSearch } from 'components/inputSearch';
import { formatDateToBack, getWeekDays, isNewImage } from 'core/utils';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { specialistSelector, specialistSlice } from '@redux/slices/specialist/specialistSlice';
import { ItemFilter, NumberFiler } from 'features/numberFiler';
import { ItemTabs, Tabs } from 'components/tabs';
import { PersonalData } from './tabsModal/personalData';
import { Service } from './tabsModal/service';
import { Workplace } from './tabsModal/workplace';
import { Schedule } from 'features/schedule';
import { GenerateDateWeek } from 'features/generateDateWeek';
import { PaginationBack } from 'features/paginationBack';
import { ModalAnt } from 'components/modalAnt';
import { DefaultButton, IconButton, WhiteButton } from 'components/buttons';
import { TabsContextProvider, useTabsContext } from './tabsModal/context';
import { Row } from './table/row';
import { ItemSpecialist } from './itemSpecialist';
import { HeaderTable } from './table/header';
import { PopUpAnt } from 'components/popupAnt';
import debounce from 'lodash/debounce';
import config from 'api/config';
import { Preloader, PreloaderLarge } from 'components/preloader';
import { Documents } from './tabsModal/documents';

export const dataFilter = [
  { id: 0, value: 10 },
  { id: 1, value: 20 },
  { id: 2, value: 40 },
  { id: 3, value: 80 },
];

const SpecialistPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const schedule = useAppSelector(specialistSelector.schedule);
  const specialists = useAppSelector(specialistSelector.specialists);
  const count = useAppSelector(specialistSelector.count);
  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState<'save' | 'delete' | 'confirmToClose' | null>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(dataFilter[0].value);
  const [week, setWeek] = useState(0);
  const [mounts, setMounts] = useState<string[]>(['', '']);
  const [dates, setDates] = useState<Moment[]>([]);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [isChanged, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<number>(0);

  const updatePage = (type: 'create' | 'remove') => {
    switch (type) {
      case 'create': {
        const nextValue = Math.ceil(count / size);
        setPage(nextValue);
        break;
      }
      case 'remove':
        if (specialists?.length === 1 && page !== 1) {
          setPage((prev) => prev - 1);
        } else {
          handledRequest();
        }
        break;
      default:
        return;
    }
  };
  const buttons: ButtonPros[] = [
    {
      id: 1,
      type: 'icon',
      icon: IconCloud,
      onClick: () => console.log('print'),
    },
    {
      id: 2,
      type: 'icon',
      icon: IconPrinter,
      onClick: () => console.log('print'),
    },
    {
      id: 3,
      title: '+ Добавить',
      onClick: () => setShowModal(true),
    },
  ];

  const dataTabs: ItemTabs[] = [
    {
      label: 'Личные данные',
      content: <PersonalData setChanged={setChanged} />,
    },
    {
      label: 'Рабочее место',
      content: <Workplace setChanged={setChanged} />,
    },
    {
      label: 'Расписание',
      content: <Schedule showSwitcher={true} setChanged={setChanged} tabsContext={useTabsContext} />,
    },
    {
      label: 'Услуги',
      content: <Service setChanged={setChanged} />,
    },
    {
      label: 'Документы',
      content: <Documents setChanged={setChanged} />,
    },
  ];

  const successDataSpecialist = useCallback(
    (data: Record<string, any>[], mounts: string[]) => {
      if (data?.length) {
        const ids = data.map((item) => item.id);
        const specialistIds = ids.filter(Boolean).join(',');

        dispatch(
          specialistSlice.actions.getScheduleRequest({
            specialist_ids: specialistIds,
            group_by: 'specialist_id',
            group_by_sub: 'date_at',
            begin_at: formatDateToBack(mounts[0]),
            end_at: formatDateToBack(mounts[1]),
          }),
        );
      }
    },
    [mounts[0]],
  );

  const handledRequest = useCallback(
    (search?: string) => {
      setLoading(true);
      dispatch(
        specialistSlice.actions.getAllSpecialistRequest({
          payload: {
            data: {
              size,
              page,
              search: search || null,
            },
            success: (data: Record<string, any>[]) => {
              setCheckSearch(!!search);
              successDataSpecialist(data, mounts);
              setLoading(false);
            },
          },
        }),
      );
    },
    [size, page, mounts[0]],
  );

  useEffect(() => {
    if (mounts[0].length) handledRequest();
  }, [size, page, mounts[0]]);

  useEffect(() => {
    const dates = getWeekDays(week);
    const startDate = dates[0];
    const endDate = moment(dates.at(-1));
    setMounts([startDate.toString(), endDate.toString()]);
    setDates(dates);
  }, [week]);

  const handleClickPagination = (value: number) => {
    setPage(value);
  };

  const handleClickFilter = (d: ItemFilter) => {
    setSize(+d.value);
  };
  const handleOnChange = (value: number) => {
    setWeek(value);
  };

  const handleCloseModal = () => {
    dispatch(specialistSlice.actions.resetData());
    setStateTabs?.((prev) => ({ ...prev, person: null }));
    setShowModal(false);
    setShowPopUp(null);
    setChanged(false);
  };

  const handleClickDelete = () => {
    if (stateTabs?.person?.data) {
      dispatch(
        specialistSlice.actions.postDeleteSpecialistRequest({
          payload: {
            data: { id: stateTabs?.person?.data?.id },
            success: () => {
              updatePage('remove');
            },
          },
        }),
      );
    }
    handleCloseModal();
  };

  const handleClick = (id: number) => () => {
    dispatch(
      specialistSlice.actions.getSpecialistIdRequest({
        payload: {
          data: { id },
          success: () => {
            setShowModal(true);
          },
        },
      }),
    );
  };

  const handleSuccess = (exit?: boolean) => {
    if (exit) {
      handleCloseModal();
    } else {
      setShowPopUp(null);
    }

    handledRequest();
    setChanged(false);
  };

  const handleClickSave = (exit?: boolean) => {
    const nextData = {
      ...stateTabs?.person?.data,
      image: isNewImage(stateTabs?.person?.data?.image),
      services: stateTabs?.person?.data?.services?.length
        ? stateTabs?.person?.data?.services?.map((i: any) => i.id ? i.id : i).join(',')
        : 'null',
      workplaces: stateTabs?.person?.data?.workplaces?.length
        ? stateTabs?.person?.data?.workplaces?.map((i: Record<string, any>) => i.id).join(',')
        : null,
      schedule: stateTabs?.person?.data?.schedule || 'null',
    };
    if (stateTabs?.person?.data?.id) {
      //UPDATE
      dispatch(
        specialistSlice.actions.postUpdateSpecialistRequest({
          payload: {
            data: nextData,
            success: () => handleSuccess(exit),
          },
        }),
      );
    } else {
      //CREATE
      dispatch(
        specialistSlice.actions.postCreateSpecialistRequest({
          payload: {
            data: nextData,
            success: () => handleSuccess(exit),
          },
        }),
      );
    }
  };

  const contentPopUp = {
    confirmToClose: {
      title: 'Закрыть окно?',
      onClick: () => handleCloseModal(),
    },
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handleClickSave(),
    },
    delete: {
      title: 'Удалить специалиста?',
      onClick: handleClickDelete,
    },
  };

  const handleClickButtonSave = (exit?: boolean) => () => {
    if (stateTabs?.person?.onIsValid?.()) {
      if (exit) {
        handleClickSave(exit);
        setShowModal(false);
      } else {
        setShowPopUp('save');
      }
    }
  };

  const handledClickTrash = () => {
    setShowPopUp('delete');
  };

  const showValue = (search: string) => {
    if (mounts[0].length) {
      setCheckSearch(true);
      setPage(1);
      handledRequest(search);
    }
  };

  const handledDebounce = useCallback(debounce(showValue, config.delaySearch), [mounts[0].length, showValue]);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  return (
    <>
      <Content>
        <ContentHeader>
          <Header title='Специалисты' buttons={buttons} />
          {(!!specialists?.length || checkSearch) && (
            <ControlBlock>
              <InputSearch title='Поиск' placeholder='Поиск' onChange={handledOnChangeSearch} value={search} />
              <GenerateDateWeek dateStart={mounts[0]} dateEnd={mounts[1]} onChange={handleOnChange} week={week} />
            </ControlBlock>
          )}
        </ContentHeader>
        {specialists && !!specialists?.length ? (
          <>
            <HeaderTable date={dates} />

            {loading ? <div style={{ marginLeft: 24, marginTop: 24 }}><PreloaderLarge loading={loading} /></div> : 
            specialists?.map((specialist) => (
              <Row
                key={specialist.id}
                data={schedule[specialist?.id.toString()]}
                dates={dates}
                specialist={<ItemSpecialist onClick={handleClick(specialist.id)} data={specialist} />}
              />
            ))}

            <Footer>
              <WrapperPagination>
                <PaginationBack page={page} size={size} count={count} onChange={handleClickPagination} />
              </WrapperPagination>
              <WrapperFilter>
                <NumberFiler title='По' data={dataFilter} onClick={handleClickFilter} />
              </WrapperFilter>
            </Footer>
          </>
        ) : checkSearch ? (
          <TextNotData>Данные не найдены</TextNotData>
        ) : (
          <TextNotData>Данные отсутствуют</TextNotData>
        )}
      </Content>

      <ModalAnt key={showModal?.toString()} show={showModal} onClose={() => isChanged ? setShowPopUp('confirmToClose') : handleCloseModal()}>
        <Tabs data={dataTabs} />
        <FooterBtn>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleClickButtonSave()}>Сохранить</DefaultButton>
            </WrapperButton>
            <WrapperButton>
              <WhiteButton onClick={handleClickButtonSave(true)}>Сохранить и выйти</WhiteButton>
            </WrapperButton>
            {loading && <Preloader loading={loading} />}
          </BLockBtn>
          {stateTabs?.person?.data?.id && (
            <WrapperIconButton>
              <IconButton onClick={handledClickTrash}>
                <IconTrash />
              </IconButton>
            </WrapperIconButton>
          )}
        </FooterBtn>
      </ModalAnt>
      {showPopUp && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </>
  );
};

export const Specialist: React.FC = (props: Record<string, any>) => {
  return (
    <TabsContextProvider>
      <SpecialistPage {...props} />
    </TabsContextProvider>
  );
};

const TextNotData = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 20px;
  font-size: 14px;
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperFilter = styled.div`
  margin-left: 3px;
`;
const WrapperPagination = styled.div`
  padding: 0 8px 0 25px;
  border-right: 1px solid var(--color-grey);
`;
const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
`;
const ControlBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;
const ContentHeader = styled.div`
  padding: 30px 30px 10px 30px;
`;
const Content = styled.div`
  width: 100%;
  padding-bottom: 80px;
`;
const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
const WrapperInput = styled.div`
  width: 378px;
`;
const SearchBlock = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0 30px;
`;

const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
  }
`;
