import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Moment } from 'moment/moment';
import { useAppSelector } from '../../../core/hooks';
import { scheduleSelector } from '@redux/slices/schedule/scheduleSlice';
import { ItemCard } from './itemCard';
import { formatDateToBack } from '../../../core/utils';

const mockData = [1, 2, 3, 4, 5, 6, 7];

type RowProps = {
  time: string;
  index: number;
  dates: Moment[];
  updateData: () => void;
};

export const Row: React.FC<RowProps> = (p) => {
  const data = useAppSelector(scheduleSelector.data);
  const [locked, setLocked] = useState(false);

  const { time, index, dates, updateData } = p;
  return (
    <Content>
      <WrapperTimeValue>
        <TimeValue>{time}</TimeValue>
      </WrapperTimeValue>
      <Body>
        {dates.map((i) => (
          <ItemCard
            key={i.toString()}
            time={time}
            date={i}
            data={data[formatDateToBack(i)]}
            index={index}
            updateData={updateData}
          />
        ))}
      </Body>
    </Content>
  );
};
const ContentItem = styled.div`
  width: 100%;
  height: 100%;
  //display: flex;
  align-items: center;
  opacity: 0;
`;

const WrapperTimeValue = styled.div`
  position: relative;
`;
const TimeValue = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #8a898e;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;
const Content = styled.div`
  height: 46px;
  display: grid;
  grid-template-columns: 73px 1fr;
`;

const Item = styled.div<{ firstRow: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-bottom: 1px solid #eff1f4;

  ${({ firstRow }) =>
    firstRow === 0
      ? css`
        border-top: 1px solid #eff1f4;
      `
      : null}
  &:first-child {
    border-left: 1px solid #eff1f4;
  }

  &:not(:last-child) {
    border-right: 1px solid #eff1f4;
  }

  &:hover ${ContentItem} {
    opacity: 1;
  }
`;
