import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled, {css} from 'styled-components';
import moment, { Moment } from 'moment';
import { DayItem } from './dayItem';
import { AddButton } from './addButton';
import { DefaultButton } from 'components/buttons';
import { SelectorStatic } from 'components/selectorStatic';
import { Preloader } from 'components/preloader';
import { useAppDispatch, useAppSelector } from 'core/hooks';
//import { scheduleCalendarSelector, scheduleCalendarSlice } from './scheduleCalendarSlice';
import { specialistSelector, specialistSlice } from '@redux/slices/specialist/specialistSlice';
import { CalendarDayGrafikTime } from './calendarDayGrafikTime';
import { CalendarDayGrafikHelp } from './calendarDayGrafikHelp';
//import { branchSelector } from 'pages/branch/branchSlice';
//import { workplaceSelector } from 'pages/workplace/workplaceSlice';

type CalendarDayProps = {
  day: Moment | any;
  prev?: boolean;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({day, prev}) => {
  //const grafikRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  //const calendar = useAppSelector(scheduleCalendarSelector.calendar);
  //const branch = useAppSelector(branchSelector.branch);
  //const branches = useAppSelector(branchSelector.branches);
  //const workplace = useAppSelector(workplaceSelector.workplace);
  //const workplaces = useAppSelector(workplaceSelector.workplaces);
  //const specialist = useAppSelector(specialistSelector.specialist);
  //const specialists = useAppSelector(specialistSelector.specialists);
  const [loading, setLoading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showGrafik, setShowGrafik] = useState<boolean>(false);
  const [isDayInPast, setDayInPast] = useState<boolean>(false);
  const [grafikSelectedFrom, setGrafikSelectedFrom] = useState<string | null>();
  const [grafikSelectedTo, setGrafikSelectedTo] = useState<string | null>();
  const [specialistSelected, setSpecialistSelected] = useState<Record<string, any> | null>();
  const [grafik, setGrafik] = useState<any[]>([]);
  const [schedule, setSchedule] = useState<any | null>();
  const [valid, setValid] = useState<boolean>(false);
  const [validError, setValidError] = useState<string>('');

  useEffect(() => {
    setGrafik(day.calendar?.map?.((item: any) => {
      return {
        ...item,
        day: day?.dayNum || +moment().format('DD'),
        week: day?.weekDayNum || moment().day(),
        month: day?.monthNum || +moment().format('MM'),
        year: day?.yearNum || +moment().format('YYYY'),
      };
    }) || []);
    
    setSchedule(day.schedule?.filter?.((item: any) =>  item?.week === moment(day).day())?.[0] || null);
    
    if (day?.dateMsec) {
      if (day.dateMsec + 86400 < moment().unix()) { // + 86400, иначе сегодняшний день считает прошлым
        setDayInPast(true);        
      }
    } else if (moment(day).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
        setDayInPast(true);
    }

    return () => {
      setGrafik([]);
      setSchedule(null);
      setDayInPast(false);
    }
  },[day]);

  useEffect(() => {
    setGrafikSelectedFrom(schedule?.data?.[0]?.grafik?.time?.filter((t: {from: string | null, to: string | null}) => t.from && t.to)?.[0]?.from || '');
    setGrafikSelectedTo(schedule?.data?.[0]?.grafik?.time?.filter((t: {from: string | null, to: string | null}) => t.from && t.to)?.[0]?.to || '');
    return () => {
      setGrafikSelectedFrom(null);
      setGrafikSelectedTo(null);
    }
  },[schedule]);

  useEffect(() => {
    setShowButton(grafik.length > 0)
  }, [grafik]);

  /*useEffect(() => {
    const onClick = e => rootEl?.current?.contains?.(e.target) || setShowGrafik(false);
    if(!addBtn?.current) {
      document.addEventListener('click', onClick);
    }
    return () => document.removeEventListener('click', onClick);
  }, []);*/

  const handleAddItemToGrafik = useCallback(() => {
    
    if (grafikSelectedFrom?.length && grafikSelectedTo?.length) {
      setLoading(true);
      
      const setData = {
        date: moment(day).format('YYYY-MM-DD'),
        time_from: grafikSelectedFrom,
        time_to: grafikSelectedTo,
        specialist_id: specialistSelected?.id || day?.specialist_id || day?.specialist?.id || null,
        workplace_id: day?.workplace_id || day?.specialist?.workplaces?.[0]?.id || null,
        branch_id: day?.branch_id || day?.specialist?.workplaces?.[0]?.branch?.id || null,
      };
      
      dispatch(
        specialistSlice.actions.postCreateSpecialistCalendarRequest({
          payload: {
            data: setData,
            success: () => {
              if (day?.specialist_id) {
                dispatch(
                  specialistSlice.actions.getSpecialistIdRequest({
                    payload: {
                      data: { id: day.specialist_id },
                      success: () => {
                        setShowGrafik(false);
                        setLoading(false);
                      },
                    },
                  }),
                );
              }
            },
          },
        }),
      );
    }
  }, [day, grafik, grafikSelectedFrom, grafikSelectedTo, specialistSelected]);

  const handleRemoveItemFromGrafik = (id: number) => {
    setLoading(true);
    dispatch(
      specialistSlice.actions.postDeleteSpecialistCalendarRequest({
        payload: {
          data: { id },
          success: () => {
            if (day?.specialist_id) {
              dispatch(
                specialistSlice.actions.getSpecialistIdRequest({
                  payload: {
                    data: { id: day.specialist_id },
                    success: () => {
                      setShowGrafik(false);
                      setLoading(false);
                    },
                  },
                }),
              );
            }
          },
        },
      }),
    );
  }

  const handleSelectSpecialist = (specialist: Record<string, any>) => {
    setSpecialistSelected(specialist);
  }

  const onMouseLeave = () => {
    //setShowGrafik(false);
  }

  useEffect(() => {
    if (showGrafik && grafikSelectedFrom && grafikSelectedTo) {
      const from = grafikSelectedFrom;
      const to = grafikSelectedTo;
      const grafikFrom = schedule?.data?.[0]?.grafik?.time?.[0]?.from || null;
      const grafikTo = schedule?.data?.[0]?.grafik?.time?.[0]?.to || null;
      const grafikRules = grafik?.map?.((item: any) => {
        return {
          [`Время не выбрано`]: !from || !from?.length || !to || !to?.length,
          [`Время начала больше или равно времени окончания`]: moment(`${item.date} ${from}`).unix() >= moment(`${item.date} ${to}`).unix(),
          [`Время не совпадает с расписанием`]: moment(`${item.date} ${from}`).unix() < moment(`${item.date} ${grafikFrom}`).unix() || moment(`${item.date} ${to}`).unix() > moment(`${item.date} ${grafikTo}`).unix(),
          [`Время уже занято`]: (moment(`${item.date} ${from}`).unix() >= moment(`${item.date} ${item.time_from}`).unix()
            && moment(`${item.date} ${from}`).unix() < moment(`${item.date} ${item.time_to}`).unix())
            || (moment(`${item.date} ${to}`).unix() > moment(`${item.date} ${item.time_from}`).unix()
            && moment(`${item.date} ${to}`).unix() <= moment(`${item.date} ${item.time_to}`).unix()),
        };
      });
      
      const error = grafikRules?.filter?.(r => Object.values(r).filter(v => v).length);
      
      if (error.length) {
        for (const key in error[0]) {
          if (!error[0][key]) delete error[0][key];
        }
      }

      setValid(error[0] ? false : true);
      setValidError(error[0] ? [...Object.keys(error[0])].join(', ') : '');
    }
    return () => {
      setValid(false);
      setValidError('');
    };
  }, [showGrafik,grafikSelectedFrom, grafikSelectedTo]);

  return (
    <Content prev={prev || isDayInPast || false} onMouseLeave={onMouseLeave}>
      <Title>{day.dayNum || moment(day).format('DD') || ''}{loading && <Preloader loading={loading} align="top" />}</Title>
      {
        <Body onMouseOver={() => setShowButton(true)} onMouseOut={() => setShowButton(grafik?.length > 0)}>
          {grafik?.map?.((el,i) =>  
            <DayItem key={i} itemsCount={grafik.length} item={el} prev={isDayInPast} onRemove={handleRemoveItemFromGrafik}/>
          ) || null}
          {!prev && showButton && !showGrafik && !isDayInPast &&
          <AddButton title="Добавить" onClick={() => setShowGrafik(true)}/>}
          {!prev && !isDayInPast && showGrafik &&
            <SelectGrafik>
              <GrafikBlock>
                <SelectorStatic 
                  data={
                    day?.specialist 
                    ? [{id: day.specialist_id, value: day.specialist.name, data: day.specialist.name}]
                    : day?.specialists?.map?.((s: any) => {return {id: s.id,value: s.title,data: s.name}})
                  }
                  disabled={day?.specialist_id ? true : false}
                  onChange={handleSelectSpecialist} 
                  valueId={day?.specialist_id || specialistSelected?.id || ''}
                />
                <CalendarDayGrafikHelp schedule={schedule} />
                {!valid && <ValidationError>{validError}</ValidationError>}
                <CalendarDayGrafikTime
                  timeFrom={grafikSelectedFrom}
                  timeTo={grafikSelectedTo}
                  setTimeFrom={setGrafikSelectedFrom} 
                  setTimeTo={setGrafikSelectedTo}
                />
                <GrafikButtons>
                  <GrafikButton onClick={handleAddItemToGrafik} disabled={loading || !valid}>Добавить</GrafikButton>
                </GrafikButtons>
                <GrafikCloseButton title="Закрыть" onClick={() => setShowGrafik(false)}>&#x2715;</GrafikCloseButton>
              </GrafikBlock>
            </SelectGrafik>
          }
        </Body>}
    </Content>
  );
};

const Content = styled.div<{prev: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 190px;
  min-height: 110px;
  border: 1px #f0f0f0 solid;
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  color: #6B6F76;
  ${({ prev }) => {
    if (prev) {
      return css`
        background-color: #f0f0f0;
        //pointer-events: none;
        opacity: 0.85;
      `;
    }
  }};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10% 0;
  width: 100%;
`;
const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3px;
  width: 100%;
  height: 100%;
  padding: 5px;
`;
const SelectGrafik = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3px;
  width: 100%;
  padding: 14px;
  text-align: center;
  background-color: #FFF;
  box-shadow: 2px 4px 16px rgba(0,0,0,0.25);
  border-radius: 5px;
`;
const GrafikBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  color: #282A2F;
  & > *:nth-child(1) {
    width: 100%;
    & > *:nth-child(1) {
      height: 32px;
      padding: 0;
    }
  }
  
`;
const GrafikButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  & > * {
    padding: 5px 8px;
  }
  & > *:nth-child(1) {
    flex: 4;
  }
  & > *:nth-child(2) {
    flex: 1;
  }
`;
const GrafikButton = styled(DefaultButton)`
`;
const GrafikCloseButton = styled.span`
  position: absolute;
  top: -12px;
  right: -10px;
  z-index: 9;
  color: var(--color-red);
  font-size: 13px;
  &:hover {
    opacity: .75;
    cursor: pointer;
  }
`;
const ValidationError = styled.div`
  border: 1px var(--color-red) solid;
  color: var(--color-red);
  padding: 4px;
  width: 100%;
`;