import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { specialistSlice } from '../../slices/specialist/specialistSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload?.payload?.success?.();
  yield;
}

function* prepareFailure(res: any) {
  yield;
}

export const deleteSpecialistCalendar = () => {
  return createRequestRest({
    url: '/api/v1/specialist/calendar_remove',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: specialistSlice.actions.postDeleteSpecialistCalendarRequest,
    method: 'delete',
  });
};
