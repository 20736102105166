import { SortableTree, TreeItemComponentProps } from 'dnd-kit-sortable-tree';
import React, { useContext, useEffect, useState } from 'react';
import { Item as TreeItem } from './Item';
import { useAppDispatch } from '@src/core/hooks';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import style from './Tree.module.scss';
import {
  SelectServiceModal
} from './modals/select-services/SelectService';
import { TreeContext } from '@src/pages/recordingPriority/tabs/appointment-flowchart-builder/tree/TreeContext';

function getNewScheme(items) {
  const scheme: any = [];

  for (const item of items) {
    scheme.push({
      id: item.id,
      type: item.type,
      children: item.children ? getNewScheme(item.children) : [],
      options: item.options,
    });
  }

  return scheme;
}

function updateItem(items, changedItem) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === changedItem.id) {
      items[i] = changedItem;
      return;
    }
    if (items[i].children) {
      updateItem(items[i].children, changedItem);
    }
  }
}

export const Tree: React.FC<any> = () => {
  const { state, updateScheme } = useContext(TreeContext);
  const [items, setItems ] = useState<any>(state.botScheme.scheme);

  useEffect(() => {
    setItems(JSON.parse(JSON.stringify(state.botScheme.scheme)) || []);
  }, [state.botScheme.scheme]);

  if (state.isLoading) return <>Loading ...</>;

  const handleOptionChange = (item) => {
    updateItem(items, item);
    updateScheme(getNewScheme(items));
    setItems([...items]);
  };

  const Item = React.forwardRef<
    HTMLDivElement,
    TreeItemComponentProps
  >(function _(props, ref) {
    return <TreeItem
      treeItemComponentProps={props}
      forwardRef={ref}
      handleOptionChange={handleOptionChange}
    />;
  });

  const onItemsChanged = (items) => {
    updateScheme(getNewScheme(items));
    setItems(items);
  };

  return (
    <div>
      <div className={style.hat}>
        <div>№</div>
        <div>Название очередности</div>
        <div>Обязательный выбор</div>
        <div>Активный шаг</div>
      </div>

        <SortableTree
          items={items}
          onItemsChanged={onItemsChanged}
          TreeItemComponent={Item}
        />

      <SelectServiceModal />
    </div>
  );
};
