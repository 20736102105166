import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';

import { transformWorkplace } from '../../../pages/workplace/utils';
import { workplaceSlice } from '../../slices/workplace/workplaceSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  let data: any = [];
  if (response?.data) {
    payload?.payload?.success?.(response?.data);
    data = transformWorkplace(response?.data);
  }
  yield put(workplaceSlice.actions.getAllWorkplaceSuccess({ data, count: response?.count }));
}

function* prepareFailure(res: any) {
  yield;
}

export const getWorkplaceAll = () => {
  return createRequestRest({
    url: '/api/v1/workplace/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: workplaceSlice.actions.getAllWorkplaceRequest,
    method: 'get',
  });
};
