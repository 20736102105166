import React from 'react';
import { useTabsContext } from '@src/pages/workplace/context';
import Title from '@src/pages/workplace/tabsContent/common/Title';
import Content from '@src/pages/workplace/tabsContent/common/Content';

export const Specialists: React.FC = () => {
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  if (!stateTabs?.workplace?.data) return <></>;

  const { specialists } = stateTabs.workplace.data;

  return (
    <Content>
      <Title>Список специалистов</Title>

      <ul>
        {specialists.map((specialist) => (
          <li key={specialist.id}>{specialist.name}</li>
        ))}
      </ul>
    </Content>
  );
};