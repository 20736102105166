import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '@redux/store/rootReducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '@redux/sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
