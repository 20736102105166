import React from 'react';
import { Form, Select as SelectAntd } from 'antd';

export const SelectWithSearch = (props) => {
  const {
    value,
    onChange,
    error,
    name,
    search,
    options,
    disabled = false,
    onBlur,
  } = props;

  return (
    <Form.Item
      name={name}
      validateStatus={error ? 'error' : ''}
      help={error}
      style={!error ? {marginBottom: '0'} : {}}
    >
      <SelectAntd
        showSearch
        value={value}
        onSearch={search}
        filterOption={false}
        onFocus={() => search()}
        onChange={onChange}
        allowClear={true}
        disabled={disabled}
        onBlur={onBlur}
      >
        {options.map((option: any) => (
          <SelectAntd.Option key={option.value} value={option.value}>
            {option.label}
          </SelectAntd.Option>
        ))}
      </SelectAntd>
    </Form.Item>
  );
};