import sweetalert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Swal = withReactContent(sweetalert)

Swal.mixin({
  //customize here
});

export default Swal;
