import { SpecialistProps } from '@redux/slices/specialist/types';

export const transformSpecialist = (data: Record<string, any>[]): SpecialistProps[] => {
  return data.map((item) => ({
    id: item.id,
    image: item?.image,
    name: [item?.surname, item?.name, item?.patronymic].join(' '),
    title: item?.name,
    profession: item?.profession,
    schedule: item?.schedule,
    calendar: item?.calendar,
    workplaces: item?.workplaces,
  }));
};

export const transformDataSearch = (data?: Record<string, any>[], active?: boolean | number[]) => {
  return (
    data?.map((item) => ({
      id: item?.id,
      active: Array.isArray(active) ? active.includes(item.id) : active || false,
      label: item?.title,
    })) || []
  );
};
