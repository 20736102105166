import { all } from 'redux-saga/effects';
import serviceSaga from '@redux/sagas/service';
import clientSaga from '@redux/sagas/client';
import specialistSaga from '@redux/sagas/specialist';
import workplaceSaga from '@redux/sagas/workplace';
import kanbanSaga from '@redux/sagas/kanban';
import authSaga from '@redux/sagas/auth';
import spaceSaga from 'redux/sagas/auth/space';
import scheduleSaga from '@redux/sagas/schedule';
import recordingPrioritySaga from 'redux/sagas/recording-priority';
import todolistSaga from '@redux/sagas/todolist';
import branchSaga from '@redux/sagas/branch';
import onlineEntrySaga from '@redux/sagas/online-entry';

export function* rootSaga() {
  yield all([
    authSaga(),
    serviceSaga(),
    clientSaga(),
    specialistSaga(),
    workplaceSaga(),
    kanbanSaga(),
    spaceSaga(),
    scheduleSaga(),
    recordingPrioritySaga(),
    todolistSaga(),
    branchSaga(),
    onlineEntrySaga(),
  ]);
}
