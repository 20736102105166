import React, { useState } from 'react';
import { kanbanSlice } from '@redux/slices/kanban/kanbanSlice';
import { useAppDispatch } from '@src/core/hooks';
import { SelectWithSearch } from './SelectWithSearch';

export const Service = (props) => {
  const {
    value,
    onChange,
    error,
    name,
    options: optionsDefault = [],
  } = props;
  const [options, setOptions] = useState<Record<any, any>[]>(optionsDefault);
  const dispatch = useAppDispatch();

  const search = (text = '') => {
    const payloadData: Record<any, any> = {};
    const options: any = [];

    if (text) payloadData.text = text;

    dispatch(
      kanbanSlice.actions.searchServicesRequest({
        payload: {
          data: payloadData,
          success: (res) => {
            for (const service of res) {
              options.push({
                value: service.id,
                label: service.title,
              });
            }

            setOptions(options);
          },
        },
      }),
    );
  };

  return (
    <SelectWithSearch
      value={value}
      onChange={onChange}
      error={error}
      name={name}
      search={search}
      options={options}
    />
  );
};