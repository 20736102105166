import React from 'react';
import { SimpleTreeItemWrapper } from 'dnd-kit-sortable-tree';
import { ItemContent } from './ItemContent';
import style from './Item.module.scss';

type TProps = {
  treeItemComponentProps: any,
  forwardRef: any
  handleOptionChange: any,
}

export const Item: React.FC<TProps> = (props: any) => {
  const { treeItemComponentProps, forwardRef, handleOptionChange} = props;

  return (
    <div className={style.item}>
      <SimpleTreeItemWrapper
        showDragHandle={true}
        {...treeItemComponentProps}
        ref={forwardRef}
        manualDrag={true}
        disableCollapseOnItemClick={true}
      >
        <ItemContent item={treeItemComponentProps.item} handleOptionChange={handleOptionChange} />
      </SimpleTreeItemWrapper>
    </div>
  );
}