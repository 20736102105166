import { call, put, takeEvery } from 'redux-saga/effects';
import makeRequest from '../../api';
// import {generateTempoMessage} from "../../Feature/tempoMessage/reducer";
const SUCCESS_STATUS = '2';

// по умолчанию обработчик формирует объект из данных и текста сообщения
const defaultPrepareSuccess = (result, successText, payload) => {
  if (typeof payload.success === 'function') {
    payload.success(result);
  }

  return {
    data: result.data,
    successText,
  };
};

// по умолчанию обработчик ошибок формирует строку из ошибки из бэка
const defaultPrepareFailure = (result, text) => `${text}. ${result.data.data}`;

// Функция для создания запросов.
// На вход 2 объекта - 1. Данные для создания запроса; 2. Данные, которые попадают в сам запрос
// Можно передавать url в данные для запроса (payload)
// Если в объекте 1 нет url, тогда идет проверка url в payload. Далее он удаляется и не передается в сам запрос

function* defaultRequestTake(
  {
    url, // путь
    failure, // событие, которое будет сгенерировано при ошибке
    success, // событие, которое будет сгенерировано при успешном ответе
    prepareRequest, // обработчик данных перед отправкой (return из этой функции - данные для запроса)
    prepareSuccess, // обработчик для успешного ответа; есть дефолтный обработчик
    failureText = 'Произошла ошибка', // текст, который будет во всплывающем сообщении при ошибке
    successText, // текст, который будет во сплывающем сообщении при успешном ответе (сообщение появляется только если передать этот параметр)
    method = 'post', //  метод запроса
    prepareFailure, // обработчик для ошибки; есть дефолтный обработчик
    propsRequest = [], // массив дополнительных параметров для запроса
    isOutputMessageFailure = true, // выводить ли сообщение об ошибке при ошибке, по умолчанию - выводить
    daData = false,
    isQuery,
    isFile = false,
    isFormData = false,
  },
  { payload, type },
) {
  try {
    // обработка данных перед отправкой, если есть prepareRequest
    const data = prepareRequest ? yield call(prepareRequest, payload, type) : payload;

    // url из данных или из payload
    const urlRequest = url || (payload && payload.url) || (data && data.url);
    const dataRequest = { ...(data || {}) };
    // если в данных есть url - удаляем его
    if (dataRequest && dataRequest.url) delete dataRequest.url;
    // если есть обработчик данных перед отправкой и он вернул false или нет url - не отправляем запрос
    // console.log({ data });
    // console.log({ prepareRequest });
    // console.log({ urlRequest });
    // if ((!data && prepareRequest) || !urlRequest) return 0;

    // отправка запроса
    const result = yield call(
      makeRequest,
      method,
      urlRequest,
      dataRequest,
      isQuery,
      isFormData,
      isFile,
      ...propsRequest,
    );
    // console.log({ result });
    const status = result?.status?.toString()?.split('');
    // console.log({ status });
    // если ошибка
    if (result?.status === 401 || status?.[0] === '5') {
      // если флаг true - выводим сообщение об ошибке
      if (isOutputMessageFailure) {
        // yield put(generateTempoMessage(result.data, 'failure', type,));
      }
      // обработка данных (переданным обработчиком или дефолтным)
      const response = prepareFailure
        ? yield call(prepareFailure, result, payload)
        : yield call(defaultPrepareFailure, result, failureText);

      // генерация события ошибки
      const failText = failureText.concat(' ', result.data.reason);
      if (failure) yield put(failure(failText, 'failure'));
    }
    // если все ок
    else {
      // обработка данных (переданным обработчиком или дефолтным)
      const response = prepareSuccess
        ? yield call(prepareSuccess, result, payload)
        : yield call(defaultPrepareSuccess, result, successText, payload);
      // console.log({ response });
      // если есть successText - выводим переданное сообщение
      // if (successText) yield put(generateTempoMessage(successText, "success", type));

      // генерация события ок
      if (success) yield put(success(response));
    }
  } catch (e) {
    console.error('Error in createRequestRest', e);
  }
}

export default function* defaultRequest(data, pattern = takeEvery) {
  // data.action - событие, при котором должен отправляться запрос
  // data.timeOut время задержки запроса если паттерн debounce
  data.timeOut
    ? yield pattern(data.timeOut, data.action, defaultRequestTake, data)
    : yield pattern(data.action, defaultRequestTake, data);
}
