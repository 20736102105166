import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { DefaultButton } from 'components/buttons';
import { Formik, Form } from 'formik';
import swal from '@src/utils/Swal';
import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { settingsSelector } from '@redux/selectors/OnlineEntrySelectors';

export const CodeButton: React.FC = () => {
  const formikRef = useRef<any>(null);
  const settings = useAppSelector(settingsSelector);
  const initialValues = {
    domain: settings.domain,
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    formikRef.current.setFieldValue('domain', settings.domain);
  }, [settings.domain])

  async function submit() {
    const errors = await formikRef.current.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    formikRef.current.setSubmitting(true);

    dispatch(onlineEntrySlice.actions.setSiteDomainRequest({
      data: {
        domain: formikRef.current.values.domain,
      },
      success() {
        swal.fire({
          icon: 'success',
          title: 'Сохранено!',
          text: 'Настройки домена сайта сохранены',
          showConfirmButton: false,
          timer: 1500,
        });

        formikRef.current.setSubmitting(false);
      }
    }));
  }

  return (
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
            values,
            setFieldValue,
            errors,
            isSubmitting
        }) => {
          return (
            <Form>
              <p style={{ marginBottom: '8px' }}>Домен</p>
              <Input
                value={values.domain}
                addonBefore="https://"
                defaultValue={initialValues.domain}
                placeholder={'ВашСайт.ру'}
                name={'domain'}
                onChange={(e) => setFieldValue('domain', e.target.value)}
                status={errors.domain ? 'error' : ''}
                allowClear
              />

              <Title style={{ marginTop: '16px' }}>
                Код необходимо скопировать на страницы вашего сайта. Копируйте код только если полностью осознаете, что
                делаете
              </Title>

              <WrapperRow>
                <CodeScript>
                  {`<script src="${window.document.location.origin}/widget.min.js"></script>`}
                </CodeScript>
              </WrapperRow>

              <WrapperButtons>
                <DefaultButton disabled={isSubmitting}>Сохранить</DefaultButton>
              </WrapperButtons>
            </Form>
          );
        }}
      </Formik>
    </Content>
  );
};

const CodeScript = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  border: 1px #dfe1e4 solid;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 2em;
  padding: 12px;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  margin-bottom: 24px;
`;

const Content = styled.div`
  padding: 40px 60px;
  position: relative;
  //pointer-events: none;
  //opacity: 0.65;
`;

const WrapperRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 100px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;