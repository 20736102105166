import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { branchSlice } from '../../slices/branch/branchSlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(branchSlice.actions.getScheduleSuccess(response?.data));
}

function* prepareFailure(res: any) {
  yield;
}

export const getDataTimeTable = () => {
  return createRequestRest({
    url: '/api/v1/todolist/timetable/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: branchSlice.actions.getScheduleRequest,
    method: 'get',
  });
};
