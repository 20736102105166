import React, {useEffect} from 'react';
import styled from 'styled-components';
import { RadioInput } from 'components/radioInput';
import { Switch } from 'components/switch';
import { Checkbox } from 'components/checkbox';
import { ScheduleRow, ButtonAddRow } from '../row';
import { ScheduleVocation} from '../vocation';
import { ScheduleResult } from '../result';
import { scheduleTypes } from '../data';
import { ScheduleCalendar } from 'features/scheduleCalendar';
import { ScheduleSwitcher } from 'features/schedule/switcher';
import { ScheduleGrafik } from '../grafik';
import { ScheduleFlexibleGrafik } from '../flexibleGrafik';
import { ScheduleShifts } from '../shifts';
import { useScheduleContext } from '../context';
import { useAppSelector } from 'core/hooks';
import { branchSelector } from '@redux/slices/branch/branchSlice';
import { workplaceSelector } from '@redux/slices/workplace/workplaceSlice';
import { specialistSelector } from '@redux/slices/specialist/specialistSlice';
import { scheduleFeatureSelector } from '@redux/slices/schedule-feature/scheduleFeatureSlice';
import {
    FlexColumn,
    ScheduleTypeBlock,
    ScheduleContainer,
    ScheduleRowTitle,
    ScheduleRowBlock,
} from '../styles';

export const ScheduleDefaultLayout: React.FC = () => {
  const branch = useAppSelector(branchSelector.branch);
  const workplace = useAppSelector(workplaceSelector.workplace);
  const specialist = useAppSelector(specialistSelector.specialist);
  const scheduleTypeNumber = useAppSelector(scheduleFeatureSelector.type);

  const {
    showSwitcher,
    commonWithParent,
    showCommonWithParent,
    scheduleType,
    flexibleGrafik,
    showShifts,
    showVocation,
    showResult,
  } = useScheduleContext();

  const {
    setCommonWithParent,
    setShowCommonWithParent,
    setScheduleType,
  } = useScheduleContext();

  const {
    handleSetByWeekDays,
    handleAddShift,
    handleFlexibleGrafik,
  } = useScheduleContext();
  
  useEffect(() => {
    if ((!workplace?.schedule || !specialist?.schedule) && !branch) {
      setShowCommonWithParent(true);
    }
    if (workplace?.schedule || specialist?.schedule) {
      setCommonWithParent(false);
    } else {
      setCommonWithParent(true);
    }
    return () => setShowCommonWithParent(false);
  }, [workplace, specialist, branch]);

return (
    <Content>
      {scheduleTypeNumber === 0 &&
      <>
        <ScheduleTypeBlock>
          {showSwitcher && <ScheduleSwitcher />}
          {showShifts &&
            <RadioInput
              title={scheduleTypes.byWeekDays.title}
              type={scheduleTypes.byWeekDays.type} 
              value={scheduleType} 
              onClick={handleSetByWeekDays}
            />
          }
          <Switch
            title='Гибкий график'
            active={flexibleGrafik}
            setActive={handleFlexibleGrafik}
          />
          {flexibleGrafik ? <ScheduleFlexibleGrafik /> : <ScheduleGrafik />}
          {showCommonWithParent &&
            <Checkbox
              disabled={scheduleType !== scheduleTypes.byWeekDays.type}
              checked={commonWithParent || false}
              onChange={() => {
                setCommonWithParent(!commonWithParent);
              }}
              label='Совпадает с расписанием по адресу'
            />
          }
        </ScheduleTypeBlock>
        {showShifts &&
          <ScheduleTypeBlock>
            <RadioInput
              title={scheduleTypes.byShifts.title}
              type={scheduleTypes.byShifts.type} 
              value={scheduleType} 
              onClick={() => {
                setScheduleType(scheduleTypes.byShifts.type);
              }}
            />
            <ScheduleContainer>
              <ScheduleRowBlock>
                <ScheduleRowTitle>Даты</ScheduleRowTitle>
                <ScheduleShifts type={'date'} titleBegin='Дата начала' titleEnd='Дата окончания' showSuffix={true} />
              </ScheduleRowBlock>
            </ScheduleContainer>
            <ButtonAddRow onAdd={handleAddShift} disabled={scheduleType !== scheduleTypes.byShifts.type} title='Добавить' />
          </ScheduleTypeBlock>
        }
        {showVocation && <ScheduleTypeBlock><ScheduleVocation /></ScheduleTypeBlock>}
        {showResult && <ScheduleTypeBlock><ScheduleResult /></ScheduleTypeBlock>}
      </>
      }
      {scheduleTypeNumber === 1 && <ScheduleCalendar showSwitcher={true} />}
    </Content>
  );
};

const Content = styled(FlexColumn)`
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    border: 0;
    min-width: 720px;
`;