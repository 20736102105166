import createRequestRest from '@src/api/createRequestRest';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';

export const updateScheme = () => {
  return createRequestRest({
    url: '/api/v1/online-entry/scheme/update',
    method: 'put',
    action: onlineEntrySlice.actions.updateSchemeRequest,
  });
};
