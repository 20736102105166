import React, { useRef } from 'react';
import { useImmer } from 'use-immer';

export type StateProps = {
  entry: {
    client: any;
    column: any;
    errors: any;
  }
};

type TabsContextProps = {
  state?: StateProps | any;
  setState?: (value?: ((prevState?: StateProps) => StateProps) | StateProps) => void;
};

export const TabsContext = React.createContext<TabsContextProps | any>({});

export const TabsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useImmer<StateProps>({
    entry: {
      client: null,
      column: null,
      errors: {},
    },
  });
  const formsRefs = useRef({
    statusAndPaidForm: null,
  })

  const actions = {
    setClient(client) {
      setState(state => {
        state.entry.client = client;
      });
    },

    setColumn(column) {
      setState(state => {
        state.entry.column = column;
      });
    },

    setFormStatusAndPaidRef(ref) {
      formsRefs.current.statusAndPaidForm = ref;
    },

    getFormStatusAndPaidRef() {
      return formsRefs.current.statusAndPaidForm;
    },

    setError(field: string, error: string) {
      setState(state => {
        if (error) {
          state.entry.errors[field] = error;
        } else {
          delete state.entry.errors[field];
        }
      });
    },
  };

  const value: any = {
    state,
    setState,
    actions,
  };

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => {
  const context = React.useContext(TabsContext);
  if (context === undefined) {
    throw new Error('useConfigState must be used within a ConfigProvider');
  }
  return context;
};
