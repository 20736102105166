import React, { useContext, useEffect } from 'react';
import { Modal } from 'antd';
import { TreeContext } from '@src/pages/recordingPriority/tabs/appointment-flowchart-builder/tree/TreeContext';
import { serviceSlice, servicesServiceSelector } from '@redux/slices/service/serviceSlice';
import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { groupServicesByCategory } from '@src/pages/service/utils/GroupServicesByCategory';
import { ServicesGroup } from './ServicesGroup';
import style from './SelectService.module.scss';

export const SelectServiceModal: React.FC = () => {
  const { isModalOpen, toggleModal, state } = useContext(TreeContext);
  const modalName = 'selectService';
  const dispatch = useAppDispatch();
  const services = useAppSelector(servicesServiceSelector);

  const handleOk = () => {
    toggleModal(modalName, false);
  };

  const handleCancel = () => {
    toggleModal(modalName, false);
  };

  useEffect(() => {
    if (state.modals.selectService.isOpen) {
      dispatch(
        serviceSlice.actions.getAllServiceRequest({}),
      );
    }
  }, [state.modals.selectService.isOpen]);

  const grouped = groupServicesByCategory(services);
  const groups: any = [];

  grouped.forEach((group, i) => {
    groups.push(<ServicesGroup key={group.title} group={group} />);
  });

  return (
    <>
      <Modal
        title="Выбрать сервисы"
        open={isModalOpen(modalName)}
        onOk={handleOk}
        onCancel={handleCancel}
        className={style.modal}
      >
        {groups}
      </Modal>
    </>
  );
};
