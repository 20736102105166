import React from 'react';
import styled from 'styled-components';
import { IconCloseSmall } from '@src/icons';
import { InputCurrency } from '@src/components/inputCurrency';
import { Textarea } from '@src/components/textarea';
import { Input } from '@src/components/input';
import { DatePicker } from '@src/components/datePicker';
import { Formik, FormikProps } from 'formik';
import { Specialist } from './fields/Specialist';
import { Branch } from './fields/Branch';
import { Time } from '@src/components/entry-modal/components/tabs-content/entry-content/service/fields/Time';
import { Service as ServiceSelect } from './fields/Service';
import { Form } from 'antd';
import { kanbanSelector } from '@redux/slices/kanban/kanbanSlice';
import { useAppSelector } from '@src/core/hooks';

export type ServiceFormInitialValues = {
  todoId: number | null;
  serviceId: number | null;
  branchId: number | null;
  specialistId: number | null;
  workplaceId: number | null;
  date: string | null;
  time: string | null;
  reason: string;
  amount: number | null;
  comment: string;
};

type TProps = {
  innerRef: any;
  countServices: number;
  onClose?: () => void;
  editTodo?: any;
};

export const Service: React.FC<TProps> = (props) => {
  const {
    innerRef,
    countServices,
    onClose,
    editTodo,
  } = props;

  const initialValues: ServiceFormInitialValues = {
    todoId: null,
    serviceId: null,
    branchId: null,
    specialistId: null,
    workplaceId: null,
    date: null,
    time: null,
    reason: '',
    amount: null,
    comment: '',
  };

  let serviceOptions: any = [];
  let specialistOptions: any = [];
  let branchOptions: any = [];
  let timeOptions: any = [];

  if (editTodo) {
    initialValues.todoId = editTodo.id;
    initialValues.serviceId = editTodo.service.id;
    initialValues.specialistId = editTodo.specialist.id;
    initialValues.workplaceId = editTodo.workplace.id;
    initialValues.branchId = editTodo.branch.id;
    initialValues.date = editTodo.date_at;
    initialValues.time = editTodo.time_at;
    initialValues.reason = editTodo.reason || '';
    initialValues.amount = editTodo.amount;
    initialValues.comment = editTodo.comment || '';

    serviceOptions = [
      {
        label: editTodo.service.title,
        value: editTodo.service.id,
      },
    ];

    specialistOptions = [
      {
        label: editTodo.specialist.fullName,
        value: editTodo.specialist.id,
      },
    ];

    branchOptions = [
      {
        label: editTodo.branch.name,
        value: editTodo.branch.id,
      },
    ];

    const [h, m] = editTodo.time_at.split(':').map((item) => parseInt(item));

    timeOptions = [
      {
        branchId: editTodo.branch.id,
        specialistId: editTodo.specialist.id,
        time: h * 60 + m,
        timeText: editTodo.time_at,
        workplaceId: editTodo.workplace.id,
      },
    ];
  }

  const resetForm = (formikProps) => {
    formikProps.resetForm();
    formikProps.setErrors({});
  };

  const onServiceChanged = (data, props: FormikProps<ServiceFormInitialValues>) => {
    resetForm(props);
    props.setFieldValue('serviceId', data);
  };

  const onDateChange = (date: string | null, props: FormikProps<ServiceFormInitialValues>) => {
    if (date === null) {
      props.setFieldValue('time', null);
    }

    props.setFieldValue('time', null);

    return props.setFieldValue('date', date);
  };

  const onTimeChange = (timePoint: any, props: FormikProps<ServiceFormInitialValues>) => {
    props.setFieldValue('time', timePoint.timeText);
    props.setFieldValue('workplaceId', timePoint.workplaceId);
    props.setFieldTouched('time');
    props.setFieldTouched('workplaceId');
  };

  function validateForm(values) {
    const errors: any = {};

    if (!values.serviceId) {
      errors.serviceId = 'Услуга обязательна';
      return errors;
    }

    if (!values.date || !values.time) {
      errors.date = 'Дата обязательна';
      errors.time = 'Время обязательно';
    }

    return errors;
  }

  return (
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={(_, actions) => {
          actions.setSubmitting(false);
        }}
        validate={validateForm}
        innerRef={innerRef}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            setFieldTouched,
          } = props;

          return (
            <Form
              initialValues={{
                service: values.serviceId,
                branch: values.branchId,
                specialist: values.specialistId,
              }}
            >
              {countServices > 1 && (
                <Close onClick={onClose}>
                  <IconCloseSmall />
                </Close>
              )}

              <Row>
                <Title>Услуга</Title>

                <ServiceSelect
                  name={'service'}
                  options={serviceOptions}
                  value={values.serviceId}
                  onChange={(data = null) => onServiceChanged(data, props)}
                  error={!touched.serviceId ? errors.serviceId : null}
                />
              </Row>

              <Row>
                <Title>Филиал</Title>

                <Branch
                  name={'branch'}
                  value={values.branchId}
                  options={branchOptions}
                  disabled={values.serviceId === null}
                  onChange={(data = null) => setFieldValue('branchId', data)}
                  onBlur={() => setFieldTouched('branchId')}
                  serviceId={values.serviceId}
                />
              </Row>

              <Row>
                <Title>Специалист</Title>

                <Specialist
                  name={'specialist'}
                  value={values.specialistId}
                  options={specialistOptions}
                  disabled={values.serviceId === null}
                  formValues={values}
                  error={!touched.specialistId ? errors.specialistId : null}
                  onBlur={() => setFieldTouched('specialistId')}
                  onChange={(data = null) => setFieldValue('specialistId', data)}
                />
              </Row>

              <Row>
                <Title>Дата и время</Title>

                <DateTimeInputsGroup>
                  <div>
                    <Form.Item
                      help={!touched.date ? errors.date : null}
                      validateStatus={!touched.date && errors.date ? 'error' : 'validating'}
                      style={!touched.date && errors.date ? {} : { marginBottom: 0}}
                    >
                      <DatePicker
                        disabled={values.serviceId === null}
                        height={32}
                        value={values.date || undefined}
                        onChange={(date) => onDateChange(date || null, props)}
                        onBlur={() => setFieldTouched('date')}
                      />
                    </Form.Item>
                  </div>

                  <div>
                    <Time
                      value={values.time}
                      options={timeOptions}
                      disabled={values.serviceId === null || values.date === null}
                      onChange={(i) => onTimeChange(i, props)}
                      values={values}
                      error={!touched.time ? errors.time : null}
                    />
                  </div>
                </DateTimeInputsGroup>
              </Row>

              <Row>
                <Title>Причина</Title>

                <div>
                  <Input
                    value={values.reason}
                    disabled={values.serviceId === null}
                    onChange={(e) => setFieldValue('reason', e.target.value)}
                    isClear
                  />
                </div>
              </Row>

              <Row>
                <Title>Стоимость</Title>

                <WrapperCurrency>
                  <InputCurrency
                    value={values.amount || ''}
                    disabled={values.serviceId === null}
                    onChange={(e) => setFieldValue('amount', parseInt(e.target.value) || null)}
                  />
                </WrapperCurrency>
              </Row>

              <TextareaBlock>
                <WrapperTextarea>
                  <Textarea
                    value={values.comment}
                    disabled={values.serviceId === null}
                    onChange={(e) => setFieldValue('comment', e.target.value)}
                    placeholder={'Комментарий к услуге'}
                  />
                </WrapperTextarea>
              </TextareaBlock>

            </Form>
          );
        }}
      </Formik>
    </Content>
  );
};

const TextareaBlock = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
`;

const WrapperTextarea = styled.div`
  position: relative;
  width: 100%;
`;

const WrapperCurrency = styled.div`
  width: 76px;
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  color: #6b6f76;
`;
const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
  cursor: pointer;
`;
const Row = styled.div`
  min-height: 32px;
  display: grid;
  grid-template-columns: 138px 1fr;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;
const Content = styled.div`
  position: relative;
  padding: 14px 20px;
  border: 1px solid #dfe1e4;
  border-radius: 16px;

  input {
    height: 32px;
    padding: 6px 12px;
  }
`;

const DateTimeInputsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-gap: 8px;
  position: relative;
  width: 100%;
`;