import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { clientsSlice } from '../../slices/clients/clientsSlice';

function* prepareRequest(payload: any) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(clientsSlice.actions.postUpdateClientSuccess(response));
  payload?.payload?.success?.(response);
}

function* prepareFailure(res: any) {
  yield;
}

export const postUpdateClients = () => {
  return createRequestRest({
    url: '/api/v1/client/update',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: clientsSlice.actions.postUpdateClientRequest,
    method: 'post',
  });
};
