import React, { useState } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import styled from 'styled-components';
import { HeadColumn } from './headColumn';
import { KartProps } from '@redux/slices/kanban/types';
import { AddCard } from './AddCard';
import { ColumnProps } from '@redux/slices/kanban/kanbanSlice';
import { ItemCard } from './ItemCard';
import { EntryModal } from '@src/components/entry-modal/EntryModal';

type ItemColumnProps = {
  item: ColumnProps;
  items: KartProps[];
};

export const ItemColumn: React.FC<ItemColumnProps> = ({ item, items }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Content>
        <HeadColumn item={item} count={items?.length} onClick={handleShowModal} />

        <Droppable key={item.prefix} droppableId={item?.id?.toString()} type={'card'}>
          {(provided) => {
            return (
              <>
                <WrapperColumn ref={provided.innerRef} {...provided.droppableProps}>
                  {items?.map((kart, index) => (
                    <WrapperItem key={kart.id.toString()}>
                      <ItemCard data={kart} index={index} onEdit={() => setShowModal(true)} />
                    </WrapperItem>
                  ))}
                  <AddCard onClick={handleShowModal} />
                </WrapperColumn>

                {provided.placeholder}
              </>
            );
          }}
        </Droppable>
      </Content>

      {showModal && (
        <EntryModal
          onCloseModal={() => setShowModal(false)}
          dataColumn={item}
        />
      )}
    </>
  );
};

const WrapperColumn = styled.div`
  height: 100%;
`;

const WrapperItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Content = styled.div`
  background: #f4f5f8;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  width: 100%;
`;
