import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { Layout } from '@src/layout/layout';
import { routingApi } from './apiRouting';
import { Service } from '@src/pages/service';
import { Clients } from '@src/pages/clients';
import { Specialist } from '@src/pages/specialist';
import { Workplace } from '@src/pages/workplace';
import { Bids } from '@src/pages/bids';
import { Schedule } from '@src/pages/schedule';
import { SignIn } from '@src/pages/auth/signIn';
import { SignUp } from '@src/pages/auth/signUp';
import { TelegramInfo } from '../pages/recordingPriority';
import { Todolist } from '@src/pages/todolist';
import { Branch } from '@src/pages/branch';

export const Routing: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index path={routingApi.crm.path} element={<Bids />} />
        <Route path={routingApi.schedule.path} element={<Schedule />} />
        <Route
          path={routingApi.data.path}
          element={
            <Routes>
              <Route path={routingApi.data.specialists.path} element={<Specialist />} />
              <Route path={routingApi.data.workplace.path} element={<Workplace />} />
              <Route path={routingApi.data.services.path} element={<Service />} />
              <Route path={routingApi.data.branches.path} element={<Branch />} />
              <Route path={routingApi.data.catalog.path} element={<div>Каталог</div>} />
              <Route path={routingApi.data.todolist.path} element={<Todolist />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>

        <Route path={routingApi.clientBase.path} element={<Clients />} />

        <Route
          path={routingApi.stock.path}
          element={
            <Routes>
              <Route path={routingApi.stock.setting.path} element={<div>Настройка</div>} />
              <Route path={routingApi.stock.story.path} element={<div>История</div>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>

        <Route
          path={routingApi.messages.path}
          element={
            <Routes>
              <Route path={routingApi.messages.setting.path} element={<div>Настройка</div>} />
              <Route path={routingApi.messages.story.path} element={<div>История</div>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>

        <Route
          path={routingApi.appointment.path}
          element={
            <Routes>
              <Route path={routingApi.appointment.setting.path} element={<div>Настройка</div>} />
              <Route path={routingApi.appointment.priority.path} element={<TelegramInfo />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>
        <Route path={routingApi.site.path} element={<div>Сайт-визитка</div>} />

        <Route
          path={routingApi.general.path}
          element={
            <Routes>
              <Route path={routingApi.general.window.path} element={<div>Окно записи</div>} />
              <Route path={routingApi.general.card.path} element={<div>Карточка клиента</div>} />
              <Route path={routingApi.general.templates.path} element={<div>Шаблоны</div>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>

        <Route
          path={routingApi.account.path}
          element={
            <Routes>
              <Route path={routingApi.account.finance.path} element={<div>Финансы</div>} />
              <Route path={routingApi.account.help.path} element={<div>Помощь</div>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        ></Route>
      </Route>

      <Route
        path="/auth/*"
        element={
          <Routes>
            <Route path={routingApi.auth.sign_in} element={<SignIn />} />

            <Route path={routingApi.auth.sign_up} element={<SignUp />} />

            <Route path="*" element={<Navigate to="/auth/sign_in" />} />
          </Routes>
        }
      ></Route>

      <Route path="*" element={<Navigate to="/auth/sign_in" />} />
    </Routes>
  );
};
