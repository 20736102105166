import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowDown, IconEyeSlash, IconEye } from 'icons';
import { Control } from './Control';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { itemsDataServiceSelector, serviceSlice } from '@redux/slices/service/serviceSlice';

export const ItemService: React.FC<any> = ({
  data,
  changeService,
  deleteService,
  copyService,
  addServiceCategory,
  level = 1,
}) => {
  const valueLeft = level * 25 + 25;
  const dispatch = useAppDispatch();

  function changeActive() {
    const checked = !data.is_active;

    dispatch(
      serviceSlice.actions.setActiveRequest({ data: { id: data.id, active: checked } }),
    );

    dispatch(serviceSlice.actions.updateService({
      id: data.id, update: { is_active: checked },
    }));
  }

  return (
    <>
      <Content left={valueLeft}>
        <WrapperTitle>
          <Title onClick={() => changeService(data)}>{data?.title}</Title>

          <IconShell>
            {data?.is_active === true ? <IconEye title={"Услуга активна"}/> : <IconEyeSlash title={"Услуга неактивна"} />}
          </IconShell>
        </WrapperTitle>

        <WrapperControl>
          <Control
            data={data}
            hiddenCopy={false}
            hiddenPlus={false}
            active={data?.is_active}
            setActive={changeActive}
            setClickPencil={() => changeService(data)}
            setClickPlus={() => addServiceCategory(data, level)}
            setClickTrash={() => deleteService(data)}
            setClickCopy={() => copyService(data)}
          />
        </WrapperControl>
      </Content>
    </>
  );
};


const IconShell = styled.div`
  margin-left: 10px;
  color: var(--color-blue);
  svg{
    width: 14px;
    height: 14px;
  }
`;
const WrapperControl = styled.div`
  padding-right: 9px;
  opacity: 0;
`;
const WrapperTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  user-select: none;
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  &:hover {
    text-decoration: underline;
  }
`;
const Content = styled.div<{ left?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ left }) => {
    return css`
      padding: 10px 0 10px ${left}px;
    `;
  }}
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-light);
  }
  &:hover {
    background: #f1f3f8;
  }
  &:hover ${WrapperControl} {
    opacity: 1;
  }
`;
