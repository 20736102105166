import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { useAppSelector } from '../core/hooks';
import { userAuthSelector } from '@redux/slices/auth/authSlice';

export const Layout: React.FC = () => {
  const { pathname } = useLocation();
  const user = useAppSelector(userAuthSelector);

  useEffect(() => {
    if (['/', '/schedule'].includes(pathname)) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [pathname]);

  return (
    <Content>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        { user ? <Outlet /> : <Navigate to='/auth' /> }
      </QueryParamProvider>
    </Content>
  );
};

const Content = styled.div`
  color: #282a2f;
  font-size: 20px;
  display: flex;
  min-width: 100%;
`;
