import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';

const clientFrontBack = (data: Record<string, any>) => {
  return {
    id: data?.id,
    is_active: data?.is_active,
    gender: data?.gender,
    surname: data?.surname,
    name: data?.name,
    patronymic: data?.patronymic,
    birthday_at: data?.birthday_at,
    profession: data?.profession,
    phone: data?.phone,
    email: data?.email,
    terminal: data?.terminal,
    channel: data?.channel,
    reason: data?.reason,
    todolistItems: data?.todolistItems,
    comment: data?.comment,
    series: data?.docs?.series,
    number: data?.docs?.number,
    code: data?.docs?.code,
    begin_at: data?.docs?.begin_at,
    issued_by: data?.docs?.issued_by,
    address_repeat: data?.address_repeat, ///
    address_register: data?.docs?.address_register,
    address_fact: data?.docs?.address_fact,
    inn: data?.docs?.inn,
    driver_license: data?.docs?.driver_license,
    car_pts: data?.docs?.car_pts,
    car_insurance: data?.docs?.car_insurance,
    car_number: data?.docs?.car_number,
    snils: data?.docs?.snils,
    policy_oms: data?.docs?.policy_oms,
    policy_dms: data?.docs?.policy_dms,
    notification_disable: data?.notification_disable, ///
  };
};

type ClientsStateProps = {
  clients: any[];
  count: number;
  client: null | Record<string, any>;
};

const initialState: ClientsStateProps = {
  clients: [],
  count: 0,
  client: null,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetClientsActive: (state: ClientsStateProps) => {
      state.client = null;
    },

    //Получение всех clients
    //---------------------------------
    getAllClientsRequest: (state: ClientsStateProps, action) => {
      //
    },
    getAllClientsSuccess: (state: ClientsStateProps, action) => {
      state.clients = action.payload?.data;
      state.count = action.payload?.count;
    },
    //Получение client по id
    //---------------------------------
    getClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    getClientSuccess: (state: ClientsStateProps, action) => {
      state.client = clientFrontBack(action.payload);
    },
    //Создание client
    //---------------------------------
    postCreateClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    postCreateClientSuccess: (state: ClientsStateProps, action) => {
      state.client = action.payload;
    },
    updateClientsTable: (state: ClientsStateProps, action) => {
      state.clients = [...state.clients, action.payload?.payload?.data];
    },
    //Обновление client
    //---------------------------------
    postUpdateClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    postUpdateClientSuccess: (state: ClientsStateProps, action) => {
      state.client = clientFrontBack(action.payload);
    },
    //Удаление client
    //---------------------------------
    deleteClientRequest: (state: ClientsStateProps, action) => {
      //
    },
    deleteClientSuccess: (state: ClientsStateProps, action) => {
      // state.clients = action.payload;
    },
  },
});

export const clientsClientsSelector = (s: RootState) => s.clients.clients;
export const clientClientsSelector = (s: RootState) => s.clients.client;
export const countClientsSelector = (s: RootState) => s.clients.count;
