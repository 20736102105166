import createRequestRest from '@src/api/createRequestRest';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(payload: Record<string, any>) {
  return payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(onlineEntrySlice.actions.setSiteDomainSuccess({ domain: payload.data.domain }));

  payload?.success();
}

export const setDomain = () => {
  return createRequestRest({
    url: '/api/v1/online-entry/set-domain',
    method: 'patch',
    prepareRequest,
    prepareSuccess,
    action: onlineEntrySlice.actions.setSiteDomainRequest,
  });
};
