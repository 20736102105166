import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { authSlice } from '../../../slices/auth/authSlice';

function* prepareRequest(payload: any) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload?.payload?.success?.();

  yield put(authSlice.actions.postSpaceSuccess(response));
}

function* prepareFailure(_res: any) {
  yield;
}

export const postSpace = () => {
  return createRequestRest({
    url: '/api/v1/space/select/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: authSlice.actions.postSpaceRequest,
    method: 'post',
    isQuery: true,
  });
};
