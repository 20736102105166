import { createContext, useEffect, useState } from 'react';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { useAppDispatch } from '@src/core/hooks';

//don't touch me
const TreeContext = createContext<{
  state: any;
  setState: any;
  toggleModal: (modalName: string, isOpen: boolean) => void;
  openModal: any;
  closeModal: any;
  isModalOpen: (modalName: string) => boolean;
  updateScheme: (scheme: any) => void;
  updateSchemeSettings: (settings: any) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
}>({});

const TreeContextProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isLoading: true,
    botScheme: {
      scheme: [],
      settings: {},
    },
    modals: {
      selectService: { isOpen: false },
    },
  });

  const toggleModal = (modalName: string, isOpen: boolean) => {
    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalName]: { isOpen },
      },
    });
  };

  const openModal = (modalName) => {
    toggleModal(modalName, true);
  };

  const closeModal = (modalName) => {
    toggleModal(modalName, false);
  };

  const isModalOpen = (modalName) => {
    return state.modals[modalName].isOpen;
  };

  const updateScheme = (scheme) => {
    state.botScheme.scheme = JSON.parse(JSON.stringify(scheme));

    setState({
      ...state,
    });

    updateBotScheme();
  };

  const updateSchemeSettings = (settings) => {
    state.botScheme.settings = JSON.parse(JSON.stringify(settings));

    setState({
      ...state,
    });

    updateBotScheme();
  };

  const updateBotScheme = () => {
    dispatch(onlineEntrySlice.actions.updateSchemeRequest({
      scheme: JSON.stringify(state.botScheme.scheme),
      settings: JSON.stringify(state.botScheme.settings),
    }));
  };

  useEffect(() => {
    dispatch(onlineEntrySlice.actions.getOnlineEntryRequest({
      success(res: Record<any, any>) {
        setState({
          ...state,
          isLoading: false,
          botScheme: {
            scheme: res.scheme || [],
            settings: res.settings || {},
          },
        });
      },
    }));
  }, []);

  return (
    <TreeContext.Provider value={{
      state,
      setState,
      toggleModal,
      openModal,
      closeModal,
      isModalOpen,
      updateScheme,
      updateSchemeSettings,
    }}>
      {children}
    </TreeContext.Provider>
  );
};

export { TreeContext, TreeContextProvider };
