import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { todolistSlice } from '../../slices/todolist/todolistSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(todolistSlice.actions.getAllTodolistSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const getTodolist = () => {
  return createRequestRest({
    url: '/api/v1/todolist/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: todolistSlice.actions.getAllTodolistRequest,
    method: 'get',
  });
};
