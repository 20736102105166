export function groupServicesByCategory(services, categories: any = []) {
  const grouped: any = {};
  const noCategory: any = {
    title: 'Услуги',
    services: [],
  };

  for (const category of categories) {
    grouped[category.id] = {
      ...category,
      services: [],
    }
  }

  for (const service of services) {
    if (service.category) {
      if (!grouped[service.category.id]) {
        grouped[service.category.id] = {
          ...service.category,
          services: [],
        };
      }

      grouped[service.category.id].services.push(service);
    } else {
      noCategory.services.push(service);
    }
  }

  const result: any = [];

  if (noCategory.services.length) {
    grouped[0] = noCategory;
  }

  Object.keys(grouped).forEach((key) => {
    result.push({
      id: parseInt(key),
      ...grouped[key],
    });
  });

  return result;
}