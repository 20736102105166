import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';

import { branchSlice } from '../../slices/branch/branchSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(branchSlice.actions.getBranchIdSuccess(response));
  payload?.payload?.success();
}

function* prepareFailure(res: any) {
  yield;
}

export const getBranchId = () => {
  return createRequestRest({
    url: '/api/v1/branch/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: branchSlice.actions.getBranchIdRequest,
    method: 'get',
  });
};
