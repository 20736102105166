/**
 * Преобразование для фронта
 * @param data
 */

export const transformServices = (data: Record<string, any>) =>
  Object.entries(data).map(([key, value]) => ({
    active: false,
    is_active: false,
    category_id: value[0]?.category_id,
    title: key,
    items: value.map((i: Record<string, any>) => ({
      active: false,
      is_active: i?.is_active,
      id: i?.id,
      category_id: i?.category_id,
      title: i?.title,
    })),
  }));

export const transformParentsSelect = (data: Record<string, any>[]) => {
  return data.map((i) => ({ id: i?.id, value: i?.title }));
};

export const transformDataSelectorCategory = (data: Record<string, any>[]): Record<string, any>[] => {
  return data?.map((item) => ({
    id: item?.id,
    category_id: item?.category_id,
    is_active: item?.is_active,
    name: item.title,
  }));
};
