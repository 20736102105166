import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../../slices/service/serviceSlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.getAllCategorySuccess(response));
}

function* prepareFailure(res: any) {
  yield;
}

export const getCategoryService = () => {
  return createRequestRest({
    url: '/api/v1/service/category/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.getAllCategoryRequest,
    method: 'get',
  });
};
