import { createSlice } from '@reduxjs/toolkit';
import { KartProps } from './types';
import { RootState } from '@redux/store';

export type ColumnProps = {
  id: string | symbol;
  prefix: string;
  name: string;
};

type KanbanStateProps = {
  column: any;
  columns: ColumnProps[];
  data: Record<string | symbol, KartProps[]> | null;
  activeCard: Record<string, any> | null;
  activeCardDefault: Record<string, any> | null;
  archive?: Record<string, any>;
};

const initialState: KanbanStateProps = {
  column: null,
  columns: [],
  data: null,
  activeCard: null,
  activeCardDefault: null,
};

const addArchive = (
  data: Record<string | symbol, KartProps[]> | null | any,
  columns: ColumnProps[],
  archive?: Record<string, any>,
) => {
  if (archive) {
    const find = data && data?.[archive.id]?.length;
    if (find) {
      return [{ id: archive?.id, name: archive?.name, prefix: 'archive' }, ...columns];
    }
    return columns;
  }
  return columns;
};

export const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    //сброс данных
    //---------------------------------
    resetActiveCard: (state: KanbanStateProps) => {
      state.activeCard = null;
    },
    //---------------------------------
    resetActiveCardDefault: (state: KanbanStateProps) => {
      state.activeCardDefault = null;
    },
    //---------------------------------
    resetArchive: (state: KanbanStateProps) => {
      state.archive = undefined;
    },

    //Предустановить данные по умолчанию
    setDataActiveCard: (state: KanbanStateProps, action) => {
      state.activeCardDefault = action.payload;
    },
    //обновление порядка колонок локально
    updateColumns: (state: KanbanStateProps, action) => {
      state.columns = action.payload;
    },
    //обновление порядка карточек локально
    updateData: (state: KanbanStateProps, action) => {
      // state.data = action.payload;
    },
    //обновление порядка карточек в колонке локально
    updateCardColumn: (state: KanbanStateProps, action) => {
      state.data = { ...state.data, [action.payload.id]: action.payload.cards };
    },

    //обновление порядка карточек в разных колонках локально
    updateCardColumns: (state: KanbanStateProps, action) => {
      state.data = {
        ...state.data,
        [action.payload.idDest]: action.payload.cardsDest,
        [action.payload.idSource]: action.payload.cardsSource,
      };
    },

    //Обновление колонки запрос
    //---------------------------------
    updateColumnTodoListRequest: (state: KanbanStateProps, action) => {
      //
    },
    updateColumnTodoListSuccess: (state: KanbanStateProps, action) => {
      // state.columns = action.payload;
      // state.data = action.payload.data;
    },

    //Обновление порядка колонок
    //---------------------------------
    updateSortColumnRequest: (state: KanbanStateProps, action) => {
      //
    },
    updateSortColumnSuccess: (state: KanbanStateProps, action) => {
      // state.columns = action.payload;
      // state.data = action.payload.data;
    },

    //Обновление порядка колонок
    //---------------------------------
    updateSortTaskRequest: (state: KanbanStateProps, action) => {
      //
    },

    updateSortTaskSuccess: (state: KanbanStateProps, action) => {
      // state.columns = action.payload;
      // state.data = action.payload.data;
    },

    //Показ/скрытие колонки архив
    //---------------------------------
    addArchiveColumn: (state: KanbanStateProps) => {
      //
      state.columns = [{ id: state?.archive?.id, name: state?.archive?.name, prefix: 'archive' }, ...state.columns];
    },
    deleteArchiveColumn: (state: KanbanStateProps) => {
      const nextColumn = state.columns?.filter((item: Record<string, any>) => item.id !== state?.archive?.id);
      state.columns = nextColumn;
    },

    //Создание колонки
    //---------------------------------
    createColumnRequest: (state: KanbanStateProps, action) => {
      //
    },
    createColumnSuccess: (state: KanbanStateProps, action) => {
      state.column = action.payload;
    },
    //Удаление колонки
    //---------------------------------
    removeColumnRequest: (state: KanbanStateProps, action) => {
      //
    },
    removeColumnSuccess: (state: KanbanStateProps, action) => {
      // state.column = action.payload;
    },

    //Получение всех колонок
    //---------------------------------
    getAllColumnTodoListRequest: (state: KanbanStateProps) => {
      //
    },
    getAllColumnTodoListSuccess: (state: KanbanStateProps, action) => {
      state.columns = action.payload;
      // state.data = action.payload.data;
    },

    //Получение всех карточек
    //---------------------------------
    getAllTodoListRequest: (state: KanbanStateProps) => {
      //
    },
    getAllTodoListSuccess: (state: KanbanStateProps | any, action) => {

      // сортировка по created_at - новые вверху
      // const payloadDataSorted = Object.keys(action.payload?.data)?.map((item) => {
      //   return action.payload?.data?.[item]?.sort((a: any,b: any) => {
      //     const date1 = new Date(b.created_at);
      //     const date2 = new Date(a.created_at);
      //     return date1.getTime() - date2.getTime();
      //   });
      // }).reduce((a, v, i) => ({ ...a, [i+1]: v}), {});

      state.columns = addArchive(action.payload?.data, action.payload.columns, state.archive);
      state.data = action.payload?.data;
      state.archive = action.payload.archive;
    },

    //Получение карточки по Id
    //---------------------------------
    getTodoListIdRequest: (state: KanbanStateProps, action) => {
      //
    },
    getTodoListIdSuccess: (state: KanbanStateProps, action) => {
      // state.todo_list = action.payload;
    },
    //Создание карточки
    //---------------------------------
    postCreateTodoListRequest: (state: KanbanStateProps, action) => {
      //
    },
    postCreateTodoListSuccess: (state: KanbanStateProps, action) => {
      // state.specialist = action.payload;
    },
    //Обновление карточки
    //---------------------------------
    postUpdateCardTodoListRequest: (state: KanbanStateProps, action) => {
      //
    },
    postUpdateCardTodoListSuccess: (state: KanbanStateProps, action) => {
      state.activeCard = action.payload;
    },
    //Удаление карточки
    //---------------------------------
    postDeleteCardTodoListRequest: (state: KanbanStateProps, action) => {
      //
    },
    postDeleteCardTodoListSuccess: (state: KanbanStateProps, action) => {
      // state.specialist = action.payload;
    },
    //Получение карточки
    //---------------------------------
    getCardTodoListRequest: (state: KanbanStateProps, action) => {
      //
    },
    getCardTodoListSuccess: (state: KanbanStateProps, action) => {
      state.activeCard = action.payload;
    },

    searchBranchRequest: (state: KanbanStateProps, action) => {
    },
    searchBranchSuccess: (state: KanbanStateProps, action) => {
    },

    searchSpecialistRequest: (state: KanbanStateProps, action) => {
    },
    searchSpecialistSuccess: (state: KanbanStateProps, action) => {
    },

    searchServicesRequest: (state: KanbanStateProps, action) => {
    },
    searchServicesSuccess: (state: KanbanStateProps, action) => {
    },
  },
});

export const kanbanSelector = {
  columns: (s: RootState): ColumnProps[] => s.kanban.columns,
  data: (s: RootState): Record<string | symbol, KartProps[]> | null => s.kanban.data,
  activeCard: (s: RootState): Record<string, any> | null => s.kanban.activeCard,
  activeCardDefault: (s: RootState): Record<string, any> | null => s.kanban.activeCardDefault,
  archive: (s: RootState): Record<string, any> | undefined => s.kanban.archive,
  // searchClients: (s: RootState): Record<TypeColumnProps, KartProps[]> | null => s.kanban.search.clients,
};
