import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header } from 'layout/header';
import { ItemTabs, Tabs } from 'components/tabs';
import { FirstScreen } from './tabs/firstScreen';
import { AppointmentFlowchartBuilder } from './tabs/appointment-flowchart-builder/AppointmentFlowchartBuilder';
import { CodeButton } from './tabs/codeButton';
import { ContactsRecords } from './tabs/contactsRecords';
import { DeliveryContacts } from './tabs/deliveryContacts';
import { TypeMessage } from './tabs/typeMessage';
import { SettingsButton } from './tabs/settingsButton';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { TabsContextProvider, useTabsContext } from './tabs/context';
import { telegramSettingsSelector } from '@redux/selectors/OnlineEntrySelectors';


export const RecordingPriority: React.FC = () => {
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();
  const telegramSettings = useAppSelector(telegramSettingsSelector);
  const dataTabs: ItemTabs[] = [
    {
      label: 'Первый экран',
      content: <FirstScreen />,
    },
    {
      label: 'Очередность записи',
      content: <AppointmentFlowchartBuilder />,
    },
    {
      label: 'Контакты при записи',
      content: <ContactsRecords />,
    },
    {
      label: 'Контакты для доставки',
      content: <DeliveryContacts />,
    },
    {
      label: 'Типовые сообщения',
      content: <TypeMessage />,
    },
    {
      label: 'Настройка функциональных кнопок',
      content: <SettingsButton />,
    },
    {
      label: 'Код кнопки',
      content: <CodeButton />,
    },
  ];
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (telegramSettings) {
      setStateTabs?.((prev) => ({ ...prev, general: { ...prev?.general, data: telegramSettings } }));
    }
  }, [telegramSettings]);

  useEffect(() => {
    dispatch(onlineEntrySlice.actions.getOnlineEntryRequest({}));
  }, []);

  return (
    <Content>
      <Header title='Запись через сайт' buttons={[]} />

      <WrapperTabs>
        <Tabs data={dataTabs} />
      </WrapperTabs>
    </Content>
  );
};

export const TelegramInfo: React.FC = (props) => {
  return (
    <TabsContextProvider>
      <RecordingPriority {...props} />
    </TabsContextProvider>
  );
};

const WrapperTabs = styled.div`
  margin-top: 50px;
`;
const Content = styled.div`
  width: 100%;
  padding: 30px 30px 150px;
`;
