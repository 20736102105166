import { EntryModalContent, TProps } from '@src/components/entry-modal/components/EntryModalContent';
import { TabsContextProvider } from '@src/components/entry-modal/context/context';

export const EntryModal: React.FC<TProps> = (props) => {
  return (
    <TabsContextProvider>
      <EntryModalContent {...props} />
    </TabsContextProvider>
  );
};
