import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';

type BranchDataProps = {
  name?: string;
  description?: string;
  address?: string;
  phone?: string;
  image_file?: any;
  is_active?: number;
  map_image_file?: string;
  ya_map?: string;
  timetables?: Record<string, any>;
} & Record<string, any>;

type BranchStateProps = {
  branches: BranchDataProps[];
  branch: BranchDataProps | null;
  count: number;
  schedule: Record<string, any>;
  search: Record<string, any>[];
};

const initialState: BranchStateProps = {
  branches: [],
  branch: null,
  count: 0,
  schedule: {},
  search: [],
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    //Сброс данных
    //---------------------------------
    resetData: (state: BranchStateProps) => {
      state.branch = null;
    },

    //---------------------------------
    resetSearch: (state: BranchStateProps) => {
      state.search = [];
    },
    //Поиск услуг
    //---------------------------------
    searchServiceRequest: (state: BranchStateProps, action) => {
      //
    },
    searchServiceSuccess: (state: BranchStateProps, action) => {
      state.search = action.payload?.services?.data;
    },

    //Получение расписания для специалистов
    //---------------------------------
    getScheduleRequest: (state: BranchStateProps, action) => {
      //
    },
    getScheduleSuccess: (state: BranchStateProps, action) => {
      state.schedule = action.payload;
    },

    //Получение всех филиалов
    //---------------------------------
    getAllBranchRequest: (state: BranchStateProps, action) => {
      //
    },
    getAllBranchSuccess: (state: BranchStateProps, action) => {
      state.branches = action.payload?.data;
      state.count = action.payload?.count;      
    },
    //Получение филиала по Id
    //---------------------------------
    getBranchIdRequest: (state: BranchStateProps, action) => {
      //
    },
    getBranchIdSuccess: (state: BranchStateProps, action) => {
      state.branch = action.payload;
    },
    //Создание филиала
    //---------------------------------
    postCreateBranchRequest: (state: BranchStateProps, action) => {
      //
    },
    postCreateBranchSuccess: (state: BranchStateProps, action) => {
      state.branch = action.payload;
    },
    //Обновление филиала
    //---------------------------------
    postUpdateBranchRequest: (state: BranchStateProps, action) => {
      //
    },
    postUpdateBranchSuccess: (state: BranchStateProps, action) => {
      state.branch = action.payload;
    },
    //Удаление филиала
    //---------------------------------
    postDeleteBranchRequest: (state: BranchStateProps, action) => {
      //
    },
    postDeleteBranchSuccess: (state: BranchStateProps, action) => {
      // state.branch = action.payload;
    },
  },
});

export const branchSelector = {
  branches: (s: RootState) => s.branch.branches,
  branch: (s: RootState) => s.branch.branch,
  count: (s: RootState) => s.branch.count,
  schedule: (s: RootState) => s.branch.schedule,
  search: (s: RootState) => s.branch.search,
};
