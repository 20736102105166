import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, {css} from 'styled-components';
import { IconSearch, IconArrowDown } from 'icons';
import { CheckboxService } from 'components/checkboxService';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { InputSearchLocal } from 'components/ui/inputSearchLocal';
import { TabsContextProps } from './types';
import { serviceSlice, servicesServiceSelector } from '@redux/slices/service/serviceSlice';

type SearchServiceSelectorProps = {
  title: string;
  placeholder: string;
  useContext: () => TabsContextProps | any;
  setChanged: (isChanged: boolean) => void;
};

export const SearchServiceSelector: React.FC<SearchServiceSelectorProps> = ({ title, placeholder, useContext, setChanged }) => {
  const dispatch = useAppDispatch();
  const services = useAppSelector(servicesServiceSelector);
  const [search, setSearch] = useState<string>('');
  const { state: stateTabs, setState: setStateTabs } = useContext();
  const activeServices = useMemo(() => {
    return stateTabs?.person?.data?.services || stateTabs?.workplace?.data?.services || stateTabs?.branch?.data?.services || [];
  }, [stateTabs?.person?.data?.services, stateTabs?.workplace?.data?.services, stateTabs?.branch?.data?.services]);
  const [activeServicesIds, setActiveServicesIds] = useState<number[]>([]);
  const [openParentIds, setOpenParentIds] = useState<number[]>([]);
  const [servicesFiltered, setServicesFiltered] = useState<any[]>([]);

  useEffect(() => {
    setServicesFiltered(services);
    dispatch(serviceSlice.actions.getAllServiceRequest({
      payload: {
        data: {},
        success: () => {
          setActiveServicesIds(activeServices?.map?.((item) => item.id) || []);
          setServicesFiltered(services);
        }
      }
    }));    
  }, []);

  useEffect(() => {
    if (services.length) {
      if (search.length) {
        const servicesFiltered: Record<string, any>[] = [];
        for (let i = 0; i < services.length; i++) {
          const service: Record<string, any> = JSON.parse(JSON.stringify(services[i]));
          service.children.length = 0;
          for (let j = 0; j < services[i].children.length; j++) {
            if (services[i].children[j].title.toLowerCase().search(search.toLowerCase()) !== -1) {
              service.children.push(services[i].children[j]);
            }
          }
          if (service.title.toLowerCase().search(search.toLowerCase()) !== -1 || service.children.length) {
            servicesFiltered.push(service);
          }
        }
        setServicesFiltered(servicesFiltered);
      } else {
        setServicesFiltered(services);
      }
    }
    return () => setServicesFiltered(services);
  }, [search, services]);

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSelectParentService = (parent: any) => {
    let ids: number[] = activeServicesIds;
    if (activeServicesIds.includes(parent.id)) {
      ids = activeServicesIds.filter((i: number) => i !== parent.id);
      parent?.children?.forEach((child: any) => {
        ids = ids.filter((i: number) => i !== child.id);
      });
    } else {
      ids.push(parent.id);
      parent?.children?.forEach((i: any) => {
        ids.push(i.id);
      });
    }
    setActiveServicesIds([...ids]);
    setChanged(true);
    if (stateTabs?.person?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        person: {
          ...prev?.person,
          data: {
            ...prev?.person?.data,
            services: [...ids],
          },
        },
      }));
    } else if (stateTabs?.workplace?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        workplace: {
          ...prev?.workplace,
          data: {
            ...prev?.workplace?.data,
            services: [...ids],
          },
        },
      }));
    } else if (stateTabs?.branch?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        branch: {
          ...prev?.branch,
          data: {
            ...prev?.branch?.data,
            services: [...ids],
          },
        },
      }));
    }
  }

  const handleSelectChildService = (child: any) => {
    let ids: number[] = activeServicesIds;
    if (activeServicesIds.includes(child.id)) {
      ids = activeServicesIds.filter(i => i !== child.id);
    } else {
      ids.push(child.id);
    }
    setActiveServicesIds([...ids]);
    setChanged(true);
    if (stateTabs?.person?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        person: {
          ...prev?.person,
          data: {
            ...prev?.person?.data,
            services: [...ids],
          },
        },
      }));
    } else if (stateTabs?.workplace?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        workplace: {
          ...prev?.workplace,
          data: {
            ...prev?.workplace?.data,
            services: [...ids],
          },
        },
      }));
    } else if (stateTabs?.branch?.data?.services) {
      setStateTabs?.((prev) => ({
        ...prev,
        branch: {
          ...prev?.branch,
          data: {
            ...prev?.branch?.data,
            services: [...ids],
          },
        },
      }));
    }
  }

  const handleShowChildren = (parent_id: number) => {
    if(openParentIds.includes(parent_id)) {
      setOpenParentIds((prev) => prev.filter(id => id !== parent_id));
    } else {
      setOpenParentIds((prev) => [...prev, parent_id]);
    }
  }

  const getSelectedChildren = useCallback((parent_id: number) => {
    const parent: Record<string, any>[] = services.filter(s => s.id === parent_id);
    const childrenIds: number[] = parent?.[0].children?.map(ch => ch.id);
    const selected = [...new Set(activeServicesIds.filter(id => id === parent_id || childrenIds.includes(id)))];
    return selected.length;
  }, [services, activeServicesIds]);  
  
  return (
    <>
      <Head>
        <Title>{title}</Title>
      </Head>
      <WrapperInput>
        <InputSearchLocal
          icon={IconSearch}
          placeholder={placeholder}
          value={search}
          onChange={handleOnChangeSearch}
          data={search}
          isClear={true}
          onClear={() => setSearch('')}
        />
      </WrapperInput>
      {servicesFiltered.map((parent) => 
        <>
          <ItemParentService key={parent.id}>
            <WrapperIconArrowDown onClick={() => handleShowChildren(parent.id)} show={openParentIds.includes(parent.id) || search.length > 0}>
              <IconArrowDown />
            </WrapperIconArrowDown>
            <CheckboxService
              active={activeServicesIds.includes(parent.id)}
              setActive={() => handleSelectParentService(parent)}
              title={parent.title}
            />
            <ItemServiceCounters>
              <span>{getSelectedChildren(parent.id)}</span>/<span>{parent.children.length + 1}</span>
            </ItemServiceCounters>
          </ItemParentService>
          {(openParentIds.includes(parent.id) || search.length > 0) && parent.children.map((child) => 
            <ItemChildService key={child.id}>
              <CheckboxService
                active={activeServicesIds.includes(child.id)}
                setActive={() => handleSelectChildService(child)}
                title={child.title}
              />
            </ItemChildService>
          )}
        </>)}
    </>
  );
};

const ItemParentService = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px 0;
  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }
  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const ItemChildService = styled(ItemParentService)`
  padding-left: 39px;
`;
const ItemServiceCounters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
  font-size: 12px;
`;
const WrapperInput = styled.div`
  margin-bottom: 24px;
`;
const WrapperIconArrowDown = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.33s ease-in-out;
  cursor: pointer;
  ${({ show }) => {
    if (show) {
      return css`
        svg {
          transform: rotate(-90deg);
          transition: all 0.33s ease-in-out;
        }
      `;
    } else {
      return css`
        transform: rotate(0deg);
      `;
    }
  }}
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;