import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { authSlice } from '../../slices/auth/authSlice';
import Cookies from 'js-cookie';

function* prepareRequest(payload: Record<string, any>) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: Record<string, any>) {
  payload?.payload?.success?.();
  localStorage.removeItem('auth');
  Cookies.remove('TOKEN');
  Cookies.remove('XSRF-TOKEN');
  yield put(authSlice.actions.getLogoutSuccess(response));
}

function* prepareFailure(res: any) {
  yield;
}

export const getLogout = () => {
  return createRequestRest({
    url: '/api/v1/auth/logout',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: authSlice.actions.getLogoutRequest,
    method: 'get',
  });
};
