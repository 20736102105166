import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { specialistSlice } from '../../slices/specialist/specialistSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;

  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload.payload?.success(response);
  yield put(specialistSlice.actions.getSpecialistAppointmentTimesSuccess(response));
}

function* prepareFailure(res: any) {
  yield;
}

export const getDaySchedule = () => {
  return createRequestRest({
    url: '/api/v1/todolist/get-appointment-time',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: specialistSlice.actions.getSpecialistAppointmentTimesRequest,
    method: 'get',
  });
};
