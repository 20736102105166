import createRequestRest from '@src/api/createRequestRest';
import { serviceSlice } from '../../slices/service/serviceSlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(payload: any) {
  return payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.setActiveSuccess(payload.data));
}

export const setActive = () => {
  return createRequestRest({
    url: '/api/v1/service/set-active',
    action: serviceSlice.actions.setActiveRequest,
    prepareSuccess,
    prepareRequest,
    method: 'patch',
  });
};
