import React, { useState } from 'react';
import { kanbanSlice } from '@redux/slices/kanban/kanbanSlice';
import { useAppDispatch } from '@src/core/hooks';
import { SelectWithSearch } from '@src/components/entry-modal/components/tabs-content/entry-content/service/fields/SelectWithSearch';

export const Specialist = (props) => {
  const {
    value,
    options: defaultOptions = [],
    disabled,
    onChange,
    formValues,
    error,
    name,
    onBlur,
  } = props;
  const [options, setOptions] = useState(defaultOptions);
  const dispatch = useAppDispatch();

  const search = (text = '') => {
    const payloadData: Record<any, any> = { serviceId: formValues.serviceId };
    const options: any = [];

    ['date', 'time', 'branchId', 'todoId'].forEach((key) => {
      if (formValues[key]) payloadData[key] = formValues[key];
    });

    if (text) payloadData.text = text;

    dispatch(
      kanbanSlice.actions.searchSpecialistRequest({
        payload: {
          data: payloadData,
          success: (res) => {
            for (const spec of res) {
              options.push({
                value: spec.id,
                label: spec.name,
              });
            }

            setOptions(options);
          },
        },
      }),
    );
  };

  return (
    <SelectWithSearch
      value={value}
      onChange={onChange}
      error={error}
      name={name}
      search={search}
      options={options}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};