import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { initialState } from '../../../features/scheduleCalendar/data';

export const scheduleCalendarSlice = createSlice({
  name: 'scheduleCalendar',
  initialState,
  reducers: {
    resetCalendar: () => initialState,
    setCalendar: (state, action: PayloadAction<any>) => {
      state.calendar = action.payload;
    },
  },
});

export const {
  resetCalendar,
  //initCalendar,
  setCalendar,
} = scheduleCalendarSlice.actions;

export const scheduleCalendarSelector = {
  calendar: (s: RootState) => s.scheduleCalendar.calendar,
};