import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { kanbanSelector, kanbanSlice } from '@redux/slices/kanban/kanbanSlice';
import { ItemTabs, Tabs } from '@src/components/tabs';
import { DefaultButton, IconButton } from '@src/components/buttons';
import { Preloader } from '@src/components/preloader';
import { IconTrash } from '@src/icons';
import { ModalAnt } from '@src/components/modalAnt';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ServiceFormInitialValues } from '@src/components/entry-modal/components/tabs-content/entry-content/service';
import Swal from '@src/utils/Swal';
import { EntryContent } from '@src/components/entry-modal/components/tabs-content/entry-content';
import { PopUpAnt } from '@src/components/popupAnt';
import { useTabsContext } from '@src/components/entry-modal/context/context';

export type TProps = {
  onCloseModal?: () => void;
  onSave?: () => void;
  dataColumn: {
    id: number;
    name: string;
  } | any;
}

export const EntryModalContent: React.FC<TProps> = (props) => {
  const {
    onCloseModal,
    onSave: onSaveCallback,
    dataColumn,
  } = props;
  const formsRefs = useRef({});
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [popUp, setPopUp] = useState<any>();
  const { state: stateTabs, setState: setStateTabs, actions } = useTabsContext();

  const handledClickClose = () => {
    if (onCloseModal) onCloseModal();

    setPopUp(null);
    dispatch(kanbanSlice.actions.resetActiveCard());
    setStateTabs?.((prev) => ({
      ...prev,
      record: {
        ...prev?.record,
        data: null,
      },
    }));
    setChanged(false);
  };

  const addFormRef = (id: string, ref) => {
    if (ref === null) return;

    formsRefs.current[id] = ref;
  };

  const removeFormRef = (id: string) => {
    delete formsRefs.current[id];
  };

  //collect data from all forms
  function collectData() {
    const formStatusAndPaid = actions.getFormStatusAndPaidRef().values;
    const data: any = {
      client_id: stateTabs.entry.client?.id,
      status_id: formStatusAndPaid.column,
      services: [],
    };

    if (formStatusAndPaid.paid) {
      data.paid = formStatusAndPaid.paid;
    }

    for (const formRefKey in formsRefs.current) {
      const values: ServiceFormInitialValues = formsRefs.current[formRefKey].values;
      const dataItem: any = {
        formId: formRefKey,
        service_id: values.serviceId,
        branch_id: values.branchId,
        specialist_id: values.specialistId,
        workplace_id: values.workplaceId,
        date_at: values.date,
        time_at: values.time,
        reason: values.reason,
        amount: values.amount,
        comment: values.comment,
      };

      if (values.todoId) {
        dataItem.todo_id = values.todoId;
      }

      data.services.push(dataItem);
    }

    return data;
  }

  async function hasErrors(data) {
    let hasErrors = false;

    actions.setError('client', data.client_id ? null : 'Клиент не выбран');

    if (data.client_id) {
      actions.setError('client', null);
    } else {
      actions.setError('client', 'Клиент не выбран');
      hasErrors = true;
    }

    for (const service of data.services) {
      const form = formsRefs.current[service.formId];

      const errors = await form.validateForm();

      if (Object.keys(errors).length > 0) {
        hasErrors = true;
      }
    }

    return hasErrors;
  }

  const handedClickRemove = () => {
    setLoading(true);
    dispatch(
      kanbanSlice.actions.postDeleteCardTodoListRequest({
        payload: {
          data: { id: activeCard?.id },
          success: () => {
            dispatch(kanbanSlice.actions.getAllTodoListRequest());
            handledClickClose();
            setLoading(false);
          },
        },
      }),
    );
  };

  const tabs: ItemTabs[] = [
    {
      label: 'Запись',
      content: <EntryContent
        data={dataColumn}
        setChanged={setChanged}
        addFormRef={addFormRef}
        removeFormRef={removeFormRef}
      />,
    },
    // {
    //   label: 'Заказ',
    //   content: <OrderContent />,
    // },
  ];

  function sendSaveRequest(data) {
    setPopUp(null);
    setChanged(false);
    setLoading(true);

    dispatch(
      kanbanSlice.actions.postCreateTodoListRequest({
        payload: {
          data,
          success: (res: Record<string, any>) => {
            setLoading(false);

            if (res.message.type === 'warning') {
              Swal.fire({
                title: 'Не сохранено',
                text: res.message.text,
                icon: 'error',
                showConfirmButton: true,
              });

              res.message.details.forEach((detail) => {
                const { formId, couldUseAnotherSpecialist = false } = detail;
                const touched: Record<any, any> = { time: false };
                const errors: Record<any, any> = { time: 'Выберите другое время' };

                if (couldUseAnotherSpecialist) {
                  touched.specialistId = false;
                  errors.specialistId = 'Попробуйте выбрать другого специалиста';
                }

                formsRefs.current[formId].setTouched(touched, false);
                formsRefs.current[formId].setErrors(errors);
              });

              return;
            }

            Swal.fire({
              title: <p>Запись сохранена</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            });

            handledClickClose();

            dispatch(kanbanSlice.actions.getAllTodoListRequest());

            if (onSaveCallback) onSaveCallback();
          },
        },
      }),
    );
  }

  async function handleSave() {
    const data = collectData();

    if (await hasErrors(data)) {
      return;
    }

    if (activeCard?.id) {
      setPopUp({
        title: 'Сохранить изменения?',

        onConfirm() {
          sendSaveRequest(data);
        },
      });
    } else {
      sendSaveRequest(data);
    }
  }

  function onClose() {
    if (!isChanged) {
      handledClickClose();
    }

    setPopUp({
      title: 'Закрыть окно?',
      onConfirm() {
        handledClickClose();
      },
    });
  }

  function onDelete() {
    setPopUp({
      title: 'Удалить карточку?',
      onConfirm() {
        handedClickRemove();
      },
    });
  }

  return (
    <>
      <ModalAnt show={true} onClose={onClose}>
        <Tabs data={tabs} />

        <FooterBtn>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleSave}>
                {activeCard?.id ? 'Сохранить' : 'Добавить прием'}
              </DefaultButton>
            </WrapperButton>

            {loading && <Preloader loading={loading} />}
          </BLockBtn>

          {activeCard?.id && (
            <WrapperIconButton>
              <IconButton onClick={onDelete}>
                <IconTrash />
              </IconButton>
            </WrapperIconButton>
          )}
        </FooterBtn>
      </ModalAnt>

      {!!popUp && (
        <PopUpAnt
          visible
          title={popUp.title}
          onClick={popUp.onConfirm}
          onCancel={() => setPopUp(null)}
        />
      )}
    </>
  );
};

const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
    color: #6b6f76;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const WrapperButton = styled.div`
  button {
    height: 40px;
  }

  &:not(:last-child) {
    margin-right: 18px;
  }
`;

const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;

const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
