import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';

function* prepareRequest(data: any) {
  return data;
  yield;
}

function* prepareSuccess(response: any, data: any) {
  data?.success?.();
  yield put(onlineEntrySlice.actions.getTelegramSettingsSuccess(response));

}

function* prepareFailure(res: any) {
  yield;
}

export const getTelegramSettings = () => {
  return createRequestRest({
    url: '/api/v1/telegram/settings/get',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: onlineEntrySlice.actions.getTelegramSettingsRequest,
    method: 'get',
  });
};
