import React, { useRef } from 'react';
import styled from 'styled-components';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';
import style from './Contacts.module.scss';
import { Formik } from 'formik';
import { Form } from 'antd';
import { DefaultButton, WhiteButton } from 'components/buttons';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { useAppDispatch, useAppSelector } from '@src/core/hooks';
import { settingsSelector } from '@redux/selectors/OnlineEntrySelectors';
import Swal from '@src/utils/Swal';

export const ContactsRecords: React.FC = () => {
  const fields = [
    {
      title: 'Поле',
      fields: [
        {
          name: 'name',
          placeholder: 'Имя',
        },
        {
          name: 'phone',
          placeholder: 'Телефон',
        },
        {
          name: 'carNumber',
          placeholder: 'Номер машины',
        },
        {
          name: 'yearOfManufacture',
          placeholder: 'Год выпуска',
        },
        {
          name: 'birthDate',
          placeholder: 'Дата рождения',
        },
        {
          name: 'giftCertificate',
          placeholder: 'Подарочный сертификат',
        },
      ],
    },
    {
      title: 'Данные о машине',
      fields: [
        {
          name: 'brand',
          placeholder: 'Марка',
        },
        {
          name: 'model',
          placeholder: 'Модель',
        },
        {
          name: 'carNumberDuplicate',
          placeholder: 'Номер машины',
        },
        {
          name: 'yearOfManufactureDuplicate',
          placeholder: 'Год выпуска',
        },
        {
          name: 'color',
          placeholder: 'Цвет',
        },
      ],
    },
  ];
  const formikRef = useRef<any>(null);
  const initialValues = {};
  const dispatch = useAppDispatch();
  const settings = useAppSelector(settingsSelector);
  const contactFields = settings.contactFields || [];

  for (const group of fields) {
    for (const field of group.fields) {
      initialValues[field.name] = contactFields.indexOf(field.name) !== -1;
    }
  }

  function onSubmit() {
    const fields: string[] = [];

    for (const field in formikRef.current.values) {
      if (formikRef.current.values[field]) fields.push(field);
    }

    dispatch(onlineEntrySlice.actions.updateContactFieldsRequest({
      data: {
        fields,
      },
      success: () => {
        dispatch(onlineEntrySlice.actions.getOnlineEntryRequest({}));

        Swal.fire({
          title: <p>Настройки сохранены</p>,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }));
  }

  return (
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
            values,
            setFieldValue,
          }) => {
          return (
            <Form onFinish={onSubmit}>
              <WrapperBlock>
                {fields.map((fieldsGroup, i) => (
                  <Block key={i}>
                    <WrapperRow>
                      <Title>{fieldsGroup.title}</Title>
                      <Title>Обязательная запись</Title>
                    </WrapperRow>

                    {fieldsGroup.fields.map((field, j) => (
                      <WrapperRow key={j}>
                        <Input className={style.input} name={field.name} placeholder={field.placeholder} />
                        <div className={style.checkboxWrapper}>
                          <Checkbox
                            checked={values[field.name]}
                            onChange={() => setFieldValue(field.name, !values[field.name])}
                          />
                        </div>
                      </WrapperRow>
                    ))}
                  </Block>
                ))}
              </WrapperBlock>

              <WrapperButtons>
                <DefaultButton>Сохранить</DefaultButton>
                <WhiteButton>Отменить</WhiteButton>
              </WrapperButtons>
            </Form>
          );
        }}
      </Formik>
    </Content>
  );
};
const WrapperBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
`;


const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: var(--color-black);
`;

const WrapperRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 100px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Block = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 45px 60px;
  position: relative;
`;

const WrapperButtons = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
`;
