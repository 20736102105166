import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { initialState } from '../../../features/schedule/data';
import { ScheduleFromToType, ScheduleType } from '../../../features/schedule/types';

export const scheduleFeatureSlice = createSlice({
    name: 'scheduleFeature',
    initialState,
    reducers: {
        resetSchedule: () => initialState,
        initSchedule: (state) => {
            state.type = initialState.type;
            state.schedule = initialState.schedule;
            state.types = initialState.types;
            state.vocation = initialState.vocation;
            state.shifts = initialState.shifts;
            console.log('initSchedule',state.type,state.schedule);
        },
        setType: (state, action: PayloadAction<number>) => {
            state.type = action.payload;
            console.log('setType',state.type);
        },
        setTypes: (state, action: PayloadAction<string[]>) => {
            state.types = action.payload;
            console.log('setTypes',state.types);
        },
        setVocation: (state, action: PayloadAction<ScheduleFromToType[] | []>) => {
            state.vocation = action.payload;
            console.log('setVocation',state.vocation);
        },
        setSchedule: (state, action: PayloadAction<ScheduleType[]>) => {
            state.schedule = action.payload;
            console.log('setSchedule',state.schedule);
        },
    },
});

export const { 
    resetSchedule, 
    initSchedule, 
    setType,
    setTypes,
    setVocation,
    setSchedule,
} = scheduleFeatureSlice.actions;

export const scheduleFeatureSelector = {
    type: (s: RootState) => s.scheduleFeature.type,
    types: (s: RootState) => s.scheduleFeature.types,
    schedule: (s: RootState) => s.scheduleFeature.schedule,
    vocation: (s: RootState) => s.scheduleFeature.vocation,
    shifts: (s: RootState) => s.scheduleFeature.shifts,
};