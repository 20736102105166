import createRequestRest from '@src/api/createRequestRest';
import { onlineEntrySlice } from '@redux/slices/online-entry/onlineEntrySlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(data: any) {
  yield {};
}

function* prepareSuccess(response: any, data: any) {
  if (data.success) yield data.success(response);
  yield put(onlineEntrySlice.actions.getOnlineEntrySuccess(response));
}

function* prepareFailure(res: any) {
  yield;
}
export const getOnlineEntry = () => {
  return createRequestRest({
    url: '/api/v1/online-entry',
    method: 'get',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: onlineEntrySlice.actions.getOnlineEntryRequest,
  });
};
