import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { kanbanSlice } from '../../slices/kanban/kanbanSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(kanbanSlice.actions.removeColumnSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const removeColumn = () => {
  return createRequestRest({
    url: '/api/v1/todolist/column/remove',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: kanbanSlice.actions.removeColumnRequest,
    method: 'delete',
  });
};
