import React from 'react';
import styled from 'styled-components';
import { Tree } from './tree/Tree';
import { TreeContextProvider } from './tree/TreeContext';

export const AppointmentFlowchartBuilder: React.FC = () => {
  return (
    <Content>
      <TreeContextProvider>
        <Tree />
      </TreeContextProvider>
    </Content>
  );
};

const Content = styled.div`
  padding: 20px 40px;
`;
