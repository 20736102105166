import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../../slices/service/serviceSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.postCreateServiceSuccess(response));
  if (response) {
    payload?.payload?.success?.(response);
  }
}

function* prepareFailure(res: any) {
  yield;
}

export const postCreateService = () => {
  return createRequestRest({
    url: '/api/v1/service/create',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.postCreateServiceRequest,
    method: 'post',
    isFormData: true,
  });
};
