import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useAppDispatch } from '@src/core/hooks';
import { specialistSlice } from '@redux/slices/specialist/specialistSlice';

export const Time = (props) => {
  const {
    value,
    options: defaultOptions = [],
    disabled,
    onChange,
    values,
    error,
  } = props;
  const [options, setOptions] = useState<Record<any, any>[]>(defaultOptions);
  const dispatch = useAppDispatch();

  const search = () => {
    const { serviceId, specialistId, branchId } = values;
    const payloadData: Record<any, any> = {
      serviceId,
      date: values.date,
    };

    if (specialistId) payloadData.specialistId = specialistId;

    if (branchId) payloadData.branchId = branchId;

    dispatch(specialistSlice.actions.getSpecialistAppointmentTimesRequest({
      payload: {
        data: payloadData,
        success: (response: any) => {
          setOptions(response);
        },
      },
    }));
  };

  let defaultValue: number | null = null;

  if (value) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].timeText === value) {
        defaultValue = i;
        break;
      }
    }
  }

  return (
    <Form.Item
      name={name}
      validateStatus={error ? 'error' : ''}
      help={error}
      style={!error ? { marginBottom: '0' } : {}}
      initialValue={defaultValue}
    >
      <Select
        onChange={(i) => {
          if (typeof i !== 'number') return;
          onChange(options[i]);
        }}
        style={{ 'width': '100%' }}
        disabled={disabled}
        value={(() => {
          for (let i = 0; i < options.length; i++) {
            if (options[i].timeText === value) return i;
          }

          return null;
        })()}
        allowClear={true}
        showSearch={true}
        onSearch={search}
        onFocus={search}
      >
        {options.map((item: Record<any, any>, i) => (
          <Select.Option key={i} value={i}>
            {item.timeText}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};