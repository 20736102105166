import createRequestRest from '@src/api/createRequestRest';
import { put } from 'redux-saga/effects';
import { authSlice } from '../../slices/auth/authSlice';

function* prepareRequest(data: { id: number }) {
  return data;
  yield;
}

function* prepareSuccess(response: any, _payload: { id: number }) {
  yield put(authSlice.actions.getTokenSuccess(response));
}

function* prepareFailure(_res: any) {
  yield;
}

export const getToken = () => {
  return createRequestRest({
    url: '/api/v1/auth/register/token',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: authSlice.actions.getTokenRequest,
    method: 'get',
  });
};
